import { useLogin } from "antopolis-react-utils/hooks/auth";
import { useContext, useState } from "react";

import { LOGIN_API } from "../../../../../Utilities/APIs/AuthAPIs/AuthAPIs";
import useAxiosAuthInstance from "../../../../../Utilities/Hooks/axiosAuthInstance";
import Form from "../../../../Partials/Forms/Form";
import EmailInput from "../../../../Partials/Forms/FormInputs/EmailInput/EmailInput";
import PasswordInput from "../../../../Partials/Forms/FormInputs/PasswordInput/PasswordInput";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { AuthContext } from "../../../../../Contexts/GlobalContexts/AuthContext";

function LoginForm() {

    const { setEmployee, setError } = useContext(AuthContext)
    const axiosAuthInstance = useAxiosAuthInstance()
    const [isEmailValid, setIsEmailValid] = useState(false);
    

    const {
        email, setEmail,
        password, setPassword,
        handleSubmit

    } = useLogin({
        axiosInstance: axiosAuthInstance,
        api: LOGIN_API,
        userKey: 'employee',
        setUser: setEmployee,
        setErrorMessage: setError
    });
  
    return (
        <Form
            onSubmit={handleSubmit}
            hasImage
        >
            <EmailInput
                label='Email'
                value={email}
                placeholder='Please Enter your Email'
                setState={setEmail}
                setIsValid={setIsEmailValid}
            />
            <PasswordInput
                label='Password'
                value={password}
                placeholder='Please Enter your Password'
                setState={setPassword}
            />
            <FormSubmitButton text='Login' />
        </Form>
    )
}

export default LoginForm
