import { useContext, useEffect, useState } from "react";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { SUBCATEGORIES } from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../../../Partials/Forms/Form";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import slugify from "react-slugify";

function UpdateSubcategory({ setShowUpdateForm, targetID, categoryID }) {

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [error, setError] = useState("");


  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  const { info: subCategory } = useGetInfo({
    axiosInstance: axiosInstance,
    api: SUBCATEGORIES + "singleSubCategory/" + targetID,
  });
  useEffect(() => {
    if (subCategory) {
      setName(subCategory.name);
      setDescription(subCategory.description);
      setPrecedence(subCategory.precedence);
    }
  }, [subCategory]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("category", categoryID);
    itemData.append("slug", slugify(name));
    itemData.append("description", description);
    itemData.append("precedence", precedence);

    try {
      const response = await axiosInstance.patch(
        SUBCATEGORIES + "/singleSubCategory/" + targetID,
        itemData
      );
      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
      }
    } catch (error) {
      setError(
        "An error occurred while updating the Subcategory. Please try again."
      );
    }
  }

  return (
    <div className="crate_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>

        <ShortTextInput
          label="Name"
          value={name}
          placeholder="Enter Name"
          setState={setName}
        />
        <ShortTextInput
          label="Description"
          value={description}
          placeholder="Enter Description"
          setState={setDescription}
        />
        {/* <NumberInput
          label="Precedence"
          value={precedence}
          placeholder="Enter Precedence"
          setState={setPrecedence}
        /> */}

        <FormSubmitButton text="Update Subcategory" />
      </Form>
    </div>
  );
}

export default UpdateSubcategory;
