import { useContext, useEffect, useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";

import {
  CATEGORIES_API,
  MANAGE_ITEMS_API,
} from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { useUseOrientation } from "../../../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { CreateButton } from "../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { ImageCell } from "../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import {
  ArchiveButton,
  CRUDButton,
  EditButton,
  ViewButton,
} from "../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import CreateItem from "./CreateItem";
import ViewItem from "./ViewItem";
import UpdateItem from "./UpdateItem";
import { RiImage2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import SearchSelectField from "../../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField";
import ArchiveItem from "../../../../../Partials/Layout/ArchiveItem/ArchiveItem";
import FilterSelect from "../../../../../Partials/Forms/FilterSelect/FilterSelect";
import "./allItem.css";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";

function AllItem() {
  const { toggleFetch, triggerFetch } = useContext(LayoutContext);
  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [filter, setFilter] = useState("active");
  const [showArchiveSection, setShowArchiveSection] = useState(false);
  const [isActive, setIsActive] = useState(null);
  // const [toggleFetch, setToggleFetch] = useState(false);
  const [type, setType] = useState("Bathware");
  const [categories, setCategories] = useState(null);
  const [category, setCategory] = useState(null);
  const [allItems, setAllItems] = useState(null);
  const navigate = useNavigate();

  const [targetID, setTargetID] = useState(null);

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();

  const typeDatas = [
    {
      name: "Bathware",
      value: "Bathware",
    },
    {
      name: "Kitchenware",
      value: "Kitchenware",
    },
  ];

  useEffect(() => {
    const hendleGetCategoryByType = async () => {
      const response = await axiosInstance.get(
        `${CATEGORIES_API}getAllCategories?type=${type}`
      );

      if (response) {
        setCategories(response?.data);
      }
    };
    hendleGetCategoryByType();
  }, [type]);
  useEffect(() => {
    const hendleGetItemsByCategory = async () => {
      const response = await axiosInstance.get(
        `${MANAGE_ITEMS_API}getAllItems?filter=${filter}&type=${type}&category=${
          category !== null ? category : ""
        }`
      );

      if (response) {
        setAllItems(response?.data);
      }
    };
    hendleGetItemsByCategory();
  }, [type, category, toggleFetch]);

  // function triggerFetch() {
  //   setToggleFetch((prevState) => !prevState);
  // }

  return (
    <div className="screen_wrapper ">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          {/* <BackButton /> */}
          <h1 className="screen_heading">
            All Items ({allItems?.length} in total)
          </h1>
        </div>
        <FilterSelect filter={filter} setFilter={setFilter} />
        <div className="item-filter-select ">
          <SearchSelectField
            label="Type"
            optionLabel="name"
            optionValue="value"
            value={type}
            options={typeDatas}
            placeholder="Select Type"
            setState={setType}
          />
          <SearchSelectField
            label="Categories"
            optionLabel="name"
            optionValue="_id"
            value={category}
            options={categories}
            placeholder="Select Category"
            setState={setCategory}
          />
        </div>
        <CreateButton
          screenTopicSingular={"Item"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Image" />
          <CRUDth th="Name" />
          <CRUDth th="Quantity" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {allItems?.map((item) => (
            <tr key={item._id}>
              <ImageCell imgSrc={item?.image} imgAlt={item.name} />
              <td>{item.name}</td>
              <td>{item?.quantity}</td>
              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      if (targetID === item._id) {
                        setTargetID("");
                      } else {
                        setShowActionButton(true);
                        setTargetID(item._id);
                      }
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={item._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={item._id}
                        setTargetID={setTargetID}
                      />
                      <ArchiveButton
                        setShowArchiveForm={setShowArchiveSection}
                        targetID={item._id}
                        setTargetID={setTargetID}
                        isActive={item?.isActive}
                        setActive={setIsActive}
                      />

                      <CRUDButton
                        handleClick={() => {
                          navigate(`/main/itemAssets/${item._id}`);
                        }}
                      >
                        <RiImage2Line />
                      </CRUDButton>
                    </div>
                  )}
                  {portrait && showActionButton && targetID === item._id && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={item._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={item._id}
                        setTargetID={setTargetID}
                        setShowActionButton={setShowActionButton}
                      />

                      <CRUDButton
                        handleClick={() => {
                          navigate(`main/itemAssets/${item._id}`);
                        }}
                      >
                        <RiImage2Line />
                      </CRUDButton>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>

        {/* <Pagination
          totalPages={totalPages}
          totalItems={totalClients}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        /> */}
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Item`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateItem setShowCreateForm={setShowCreateForm} />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Item`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateItem
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Item`}
          setShowModalContent={setShowViewSection}
        >
          <ViewItem targetID={targetID} />
        </Modal>
      )}

      {showArchiveSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Archive Item`}
          setShowModalContent={setShowArchiveSection}
        >
          <ArchiveItem
            api={`${MANAGE_ITEMS_API}archiveItem/`}
            isActive={isActive}
            targetID={targetID}
            setShowActionButton={setShowArchiveSection}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </div>
  );
}

export default AllItem;
