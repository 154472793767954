import { useGetInfo } from "antopolis-react-utils/hooks";
import { ORDERS_API } from "../../../../../Utilities/APIs/OrderAPIs/OrderAPIs";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";


function ViewOrder({ targetID }) {

  const axiosInstance = useAxiosInstance()

  const { info: order } = useGetInfo({
    axiosInstance: axiosInstance,
    api: ORDERS_API + "singleOrder/" + targetID,
  });

  return (
    <div className="crud_view_content">

      <h1>Order ID</h1>
      <p>{order?._id}</p>

      <h1>Customer Name</h1>
      <p>{order?.name ? order?.name : order?.customer?.name}</p>

      <h1>Customer Email</h1>
      <p>{order?.email ? order?.email : order?.customer?.email}</p>

      {order?.isAdmin && <>
        <h1>Employee Name</h1>
        <p>{order?.employee?.name}</p>

        <h1>Employee Email</h1>
        <p>{order?.employee?.email}</p>
      </>}

      <h1>Order Date</h1>
      <p>{new Date(order?.createdAt).toLocaleDateString()}</p>

     

      <h1>Full Address</h1>
      <p>{order?.fullAddressString}</p>

      <h1>Area</h1>
      <p>{order?.area}</p>

      <h1>District</h1>
      <p>{order?.district}</p>

      <h1>Division</h1>
      <p>{order?.division}</p>

      <h1>Subtotal</h1>
      <p>{order?.subtotal}</p>

      <h1>Delivery Fee</h1>
      <p>{order?.deliveryFee}</p>

      <h1>Total</h1>
      <p>{order?.total}</p>

      <h1>VAT</h1>
      <p>{order?.vat}</p>

      <h1>Grand Total</h1>
      <p>{order?.grandTotal}</p>


    </div>
  )
}

export default ViewOrder