import { useState } from "react";
import useAxiosAuthInstance from "../../../../../../Utilities/Hooks/axiosAuthInstance";

function ViewOrder() {
  const [customer, setCustomer] = useState(null);
  const axiosInstance = useAxiosAuthInstance();
  return (
    <div className="crud_view_content">
      <h1>Name</h1>
      <p>{customer?.name}</p>

      <h1>Email</h1>
      <p>{customer?.email}</p>

      <h1>Phone</h1>
      <p>{customer?.mobile}</p>
    </div>
  );
}

export default ViewOrder;
