import { useEffect, useState, useContext } from "react";
import slugify from "react-slugify";
import { useGetInfo } from "antopolis-react-utils/hooks";
import {
  CATEGORIES_API,
  MANAGE_ITEMS_API,
  SUBCATEGORIES,
} from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import Form from "../../../../../Partials/Forms/Form";
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import SearchSelectField from "../../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField";
import NumberInput from "../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import CustomEditor from "../../../../../Partials/Elements/CustomEditor/CustomEditor";
import SwitchInput from "../../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";
import SelectInput from "../../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";

function UpdateItem({ targetID, setShowUpdateForm }) {

  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [image, setImage] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [name, setName] = useState("");
  const [sku, setSku] = useState("");
  const [description, setDescription] = useState("");
  const [specification, setSpecification] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [isDiscount, setIsDiscount] = useState(false);
  const [discount, setDiscount] = useState("");
  const [error, setError] = useState("");

  const { triggerFetch, toggleFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  const { info: categories } = useGetInfo({
    axiosInstance: axiosInstance,
    api: CATEGORIES_API + "getAllCategories?type=" + type,
    toggleFetch,
  });

  const { info: subCategories } = useGetInfo({
    axiosInstance: axiosInstance,
    api: SUBCATEGORIES + "singleSubCategoriesByCategory/" + category,
    toggleFetch,
  });

  const { info: discounts } = useGetInfo({
    axiosInstance: axiosInstance,
    api: "ecommerceSettings/discounts/" + "getAllDiscounts",
    toggleFetch
  });
  useEffect(() => {
    async function fetchItemDetails() {
      try {
        const response = await axiosInstance.get(`${MANAGE_ITEMS_API}singleItem/${targetID}`);

        const item = response.data;
        setType(item.type);
        setCategory(item.category._id);
        setSubCategory(item.subCategory);
        setImage(item.image);
        setThumbnail(item.thumbnail);
        setName(item.name);
        setSku(item.sku);
        setDescription(item.description);
        setSpecification(item.specification);
        setVideoUrl(item.videoUrl);
        setPrice(item?.price);
        setQuantity(item?.quantity);
        setIsDiscount(item?.isDiscount)
        setDiscount(item?.discount)
      } catch (error) {
        setError("An error occurred while fetching the item details. Please try again.");
      }
    }

    fetchItemDetails();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("type", type);
    itemData.append("category", category);
    subCategory && itemData.append("subCategory", subCategory);
    itemData.append("name", name);
    itemData.append("sku", sku);
    itemData.append("image", image);
    itemData.append("slug", slugify(name));
    itemData.append("description", description);
    itemData.append("specification", specification);
    itemData.append("thumbnail", thumbnail);
    itemData.append("videoUrl", videoUrl);
    itemData.append("price", price);
    itemData.append("quantity", quantity);
    itemData.append("isDiscount", isDiscount);
    isDiscount && itemData.append("discount", discount);

    try {
      const response = await axiosInstance.patch(
        `${MANAGE_ITEMS_API}singleItem/${targetID}`,
        itemData
      );
      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
      }
    } catch (error) {
      setError("An error occurred while updating the item. Please try again.");
    }
  }

  return (
    <div className="update_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowUpdateImage
        >
          Upload Product Image (550 x 650)
        </ImageInput>
        <SelectInput
          label="Select Type"
          value={type}
          setState={setType}
        >
          <option value="" hidden>Select Type</option>
          <option value="Bathware">Bathware</option>
          <option value="Kitchenware">Kitchenware</option>
        </SelectInput>
        <SearchSelectField
          label="Categories"
          value={category}
          optionLabel="name"
          optionValue="_id"
          options={categories}
          placeholder="Enter Category"
          setState={setCategory}
        />
        {
          category && <SearchSelectField
            label="Subcategories"
            value={subCategory}
            optionLabel="name"
            optionValue="_id"
            options={subCategories}
            placeholder="Enter Subcategory"
            setState={setSubCategory}
          />
        }
        <ShortTextInput
          label="Name"
          value={name}
          placeholder="Enter Name"
          setState={setName}
        />
        <ShortTextInput
          label="SkU"
          value={sku}
          placeholder="Enter Product SkU"
          setState={setSku}
        />
        <CustomEditor label="Description" setState={setDescription} data={description || ""} />
        <CustomEditor label="Specification" setState={setSpecification} data={specification || ""} />
        <ImageInput
          fieldId="2"
          state={thumbnail}
          setState={setThumbnail}
          allowUpdateImage
        >
          Upload  video thumbnail (1250 x 750)
        </ImageInput>
        <ShortTextInput
          label="Video Url"
          value={videoUrl}
          placeholder="Enter Video URL"
          setState={setVideoUrl}
        />
        <ShortTextInput
          label="Slug"
          value={slugify(name)}
          placeholder="Enter Slug"
        />
        <NumberInput
          label="Price"
          value={price}
          setState={setPrice}
          placeholder="Enter Price"
        />
        <NumberInput
          label="Quantity"
          value={quantity}
          setState={setQuantity}
          placeholder="Enter Quantity"
        />
        <SwitchInput
          label={"IsDiscount"}
          toggleSwitch={() => setIsDiscount((prevState) => !prevState)}
          checked={isDiscount}
        />
        {isDiscount && <SearchSelectField
          label="Discount"
          optionLabel="amount"
          optionValue="_id"
          value={discount}
          options={discounts}
          placeholder="Enter Discounts"
          setState={setDiscount}
        />}
        <FormSubmitButton text="Update Item" />
      </Form>
    </div>
  );
}

export default UpdateItem;
