import {  useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"
import { DEALER_AREA_API } from '../../../../../Utilities/APIs/DealerDivisionAPIs/DealerDivisionAPIs';


function CreateDealerArea({ setShowCreateForm, triggerFetch, dealerDivisionID }) {

    const [name, setName] = useState('')

    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('name', name)
        itemData.append('dealerDivision', dealerDivisionID)

        const response = await axiosInstance.post(DEALER_AREA_API + 'createDealerArea', itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <ShortTextInput
                    label={`Name`}
                    value={name}
                    placeholder={`Enter Name`}
                    setState={setName}

                />

                <FormSubmitButton text="Create Dealer Area" />
            </Form>
        </div >
    )
}

export default CreateDealerArea