import { useState } from "react";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Forms/Form";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import CustomEditor from "../../../../Partials/Elements/CustomEditor/CustomEditor";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";

function CreateBlog({ api, setShowCreateForm, triggerFetch }) {

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [newsAndEventsimage, setNewsAndEventsImage] = useState("");
    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()

        itemData.append('title', title)
        itemData.append('description', description)
        itemData.append('image', newsAndEventsimage)

        const response = await axiosInstance.post(api, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit} hasImage>
                <ShortTextInput
                    label={`Title`}
                    value={title}
                    placeholder={`Enter Title`}
                    setState={setTitle}

                />

                <CustomEditor label="Description" setState={setDescription} data={description || ""} />

                <ImageInput
                    fieldId="1"
                    state={newsAndEventsimage}
                    setState={setNewsAndEventsImage}
                    allowCreateImage
                >
                    Upload  Image (550 x 450)
                </ImageInput>
                <FormSubmitButton text="Create Blog" />
            </Form>
        </div>
    )
}

export default CreateBlog