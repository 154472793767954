import { useContext, useState } from "react";
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import {
  COLLECTIONS_API,
  CONTROL_ITEMS_API,
  ITEM_COLLECTIONS_API,
  ITEMS_API,
  NAV_COLLECTION_API,
} from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import ShortTextInput from "../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../../../../Partials/Forms/Form";
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useGetInfo } from "antopolis-react-utils/hooks";
import SearchSelectField from "../../../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField";
import NumberInput from "../../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";

function CreateItemCollection({ setShowCreateForm, collectionID }) {
  const [item, setItem] = useState(null);
  const [collection, setCollection] = useState("");
  const [error, setError] = useState("");

  const { triggerFetch, toggleFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  console.log(item, 'item')
  // const { info: collections } = useGetInfo({
  //   axiosInstance: axiosInstance,
  //   api: COLLECTIONS_API + "getAllCollections",
  //   toggleFetch,
  // });

  const { info: items } = useGetInfo({
    axiosInstance: axiosInstance,
    api: CONTROL_ITEMS_API + "getAllItems",
    toggleFetch,
  });

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = {
      item: item,
      collectionID: collectionID,
    };

    try {
      const response = await axiosInstance.post(
        ITEM_COLLECTIONS_API + "createItemCollection",
        itemData
      );
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
      }
    } catch (error) {
      setError(
        "An error occurred while creating the item collection. Please try again."
      );
    }
  }

  return (
    <div className="create_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <SearchSelectField
          search
          label="Items"
          optionLabel="name"
          optionValue="_id"
          value={item}
          options={items}
          placeholder="Enter Description"
          setState={setItem}
        />
        <div className="input_group" style={{ minHeight: '300px' }}>

        </div>

        <FormSubmitButton text="Create Item Collection" />
      </Form>
    </div>
  );
}

export default CreateItemCollection;
