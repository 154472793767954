import { useContext, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"
import SwitchInput from "../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput"

function CreateDeliveryFee({ api, setShowCreateForm, triggerFetch }) {

    const [name, setName] = useState('')
    const [amount, setAmount] = useState('')
    const [isApplicable, setIsApplicable] = useState(false)

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('name', name)
        itemData.append('amount', amount)
        itemData.append('isApplicable', isApplicable)
        // itemData.append('isActive', true)

        const response = await axiosInstance.post(api + 'createDeliveryFee', itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <ShortTextInput
                    label={`Name`}
                    value={name}
                    placeholder={`Enter Name`}
                    setState={setName}

                />
                <NumberInput
                    label={`amount`}
                    value={amount}
                    placeholder={`Enter amount`}
                    setState={setAmount}

                />
                <SwitchInput
                    label={"visibility"}
                    toggleSwitch={() => setIsApplicable((prevState) => !prevState)}
                    checked={isApplicable}
                />

                <FormSubmitButton text="Create Delivery Fee" />
            </Form>
        </div >
    )
}

export default CreateDeliveryFee