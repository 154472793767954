import {useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Forms/Form"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"

function CreateContactInfo({ api, setShowCreateForm, triggerFetch }) {
   
     const [email, setEmail] = useState('')
     const [phone, setPhone] = useState('')
     const [location, setLocation] = useState('')
    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = {
            email,
            phone,
            location
        }

        const response = await axiosInstance.post(api, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <ShortTextInput
                    label={`Email`}
                    value={email}
                    placeholder={`Enter Email`}
                    setState={setEmail}
                />
                <ShortTextInput
                    label={`Phone`}
                    value={phone}
                    placeholder={`Enter Phone`}
                    setState={setPhone}
                />
                <ShortTextInput
                    label={`Location`}
                    value={location}
                    placeholder={`Enter Location`}
                    setState={setLocation}
                />


                <FormSubmitButton text="Create Contact Info" />
            </Form>
        </div>
    )
}

export default CreateContactInfo