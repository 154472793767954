import { useContext, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Forms/Form"
import EmailInput from "../../../../Partials/Forms/FormInputs/EmailInput/EmailInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import SearchSelectField from "../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField"
import DateInput from "../../../../Partials/Forms/FormInputs/DateInput/DateInput"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"
import PasswordInput from "../../../../Partials/Forms/FormInputs/PasswordInput/PasswordInput"
import { AuthContext } from "../../../../../Contexts/GlobalContexts/AuthContext"
import { LayoutContext } from "../../../../../Contexts/GlobalContexts/LayoutContext"

function CreateCustomers({ setShowCreateForm }) {
    const { employee } = useContext(AuthContext)
    const { triggerFetch } = useContext(LayoutContext)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [gender, setGender] = useState('')
    // const [dateOfBirth, setDateOfBirth] = useState('')
    const [isEmailValid, setIsEmailValid] = useState(false);
    const axiosInstance = useAxiosInstance()
   
    async function handleSubmit(e) {
        e.preventDefault()
        const itemData = {}
        itemData.name = name
        itemData.email = email
        itemData.password = password
        itemData.mobile = phoneNumber
        itemData.gender = gender
        // itemData.dateOfBirth = dateOfBirth
        itemData.createBy = employee?._id
        const response = await axiosInstance.post("customers/creatCustomer", itemData,)
        if (response) {
            setEmail('')
            setShowCreateForm(false)
            triggerFetch()
        }

        console.log(response)
    }
    const genders = [
        {
            name: "Male",
            value: "male"
        },
        {
            name: "Female",
            value: "female"
        },
        {
            name: "Other",
            value: "other"
        },
    ]

    return (
        <Form onSubmit={handleSubmit}>
            <ShortTextInput
                label="Name"
                value={name}
                placeholder="Enter Name"
                setState={setName}
            />
            <EmailInput
                label={'Email'}
                value={email}
                placeholder={'Please Enter an Email'}
                setState={setEmail}
                setIsValid={setIsEmailValid}
            />
            <PasswordInput
                label="Password"
                value={password}
                setState={setPassword}
                placeholder="Enter Password"
            />
            <NumberInput
                label="Phone Number"
                value={phoneNumber}
                setState={setPhoneNumber}
                placeholder="Enter Phone Number"
            />
            <SearchSelectField
                label="Gender"
                optionLabel="name"
                optionValue="value"
                value={gender}
                options={genders}
                placeholder="Select gender"
                setState={setGender}
            />
            {/* <DateInput
                label={`Date of Birth`}
                value={dateOfBirth}
                placeholder={`Enter Date of Birth`}
                setState={setDateOfBirth}
            /> */}
            <FormSubmitButton text='Creat New Customer' />
        </Form>
    )

}

export default CreateCustomers