import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";


function ViewContactInfo({
    targetID,
    api
}) {

    const axiosInstance = useAxiosInstance();

    const { info: contactInfo } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + 'getSingleContactInfo/' + targetID,
    })


    return (

        <div className="crud_view_content">

            {contactInfo &&
                <>

                    <h1>Email</h1>
                    <p>{contactInfo.email}</p>

                    <h1>Phone</h1>
                    <p>{contactInfo.phone}</p>

                    <h1>Location</h1>
                    <p>{contactInfo.location}</p>

                </>
            }
        </div>
    )
}

export default ViewContactInfo