import { useGetInfo } from "antopolis-react-utils/hooks";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../../../../Contexts/GlobalContexts/AuthContext";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import {
    ViewButton
} from "../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import ViewWishlist from "./ViewWishlist";

function WishlistFromCustomer({ api }) {
  const navigate = useNavigate();
  const { customerID } = useParams();
  const [showViewSection, setShowViewSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);
  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();
  const { info: wishlists  } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "wishlists/" + customerID,
    toggleFetch,
  });
  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">{`Wishlists  (${wishlists?.length} in total)`}</h1>
        </div>
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {wishlists  &&
            wishlists?.map((e) => (
              <tr key={e._id}>
                <ShortTextCell text={e.name} />
                <td>
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={e._id}
                      setTargetID={setTargetID}
                    />
                   
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CRUDTable>
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Wishlist`}
          setShowModalContent={setShowViewSection}
        >
          <ViewWishlist targetID={targetID} api={api} />
        </Modal>
      )}
    </div>
  );
}

export default WishlistFromCustomer;
