
function OrderDelivered({
    setShowModal, setShowProcessingOrder, targetID, handleStatusChange, status
}) {



    return (
        <div className='modal_delete_content'>
            <p style={{ textAlign: 'center', color: 'White' }}>
                {status === 'processing'&& "Do you really want to proceed with processing the order?" }
                { status === 'toBeDelivered' && "Do you really want the order to be shipped?" }
                { status === 'shipped' && "Do you really want to complete the order?"}
                {/* Are you sure you want to deliver this order? */}

            </p>

            <div className="modal_group_buttons">
                <button
                    className="delete"
                    onClick={() => handleStatusChange(targetID)}
                >Accept</button>

                <button className="cancel" onClick={() => {
                    setShowModal(false);
                    setShowProcessingOrder(false)
                }}>Cancel</button>
            </div>

        </div>
    )
}

export default OrderDelivered