import React from "react";
import { RiCollageFill, RiGridFill } from "react-icons/ri";
import { ScreenHolder } from "../../../../../Partials/Layout/ScreenHolder/ScreenHolder";
import { NavCard } from "../../../../../Partials/Layout/NavCardList/NavCard/NavCard";
import { NavCardList } from "../../../../../Partials/Layout/NavCardList/NavCardList";

function CollectionGroup() {
  return (
    <ScreenHolder>
      <NavCardList numOfCards={"two"}>
        <NavCard
          cardName={"All Collection"}
          navCardLink="/main/collection/allCollection"
        >
          <RiGridFill />
        </NavCard>
        <NavCard
          cardName={"Nav COLLECTION"}
          navCardLink="/main/collection/navCollection"
        >
          <RiCollageFill />
        </NavCard>
      </NavCardList>
    </ScreenHolder>
  );
}

export default CollectionGroup;
