import { useContext } from "react";
import { useCart } from "../../../../../Utilities/Hooks/useCart"
import { CRUDButton, ViewButton } from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons"
import { ImageCell } from "../../../../Partials/Layout/CRUD/ImageCell/ImageCell"
import { ShortTextCell } from "../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell"
import { FaPlus, FaMinus } from "react-icons/fa";
import { LayoutContext } from "../../../../../Contexts/GlobalContexts/LayoutContext";
import { takaFormatter } from "../../../../../Utilities/Hooks/TakaFormatter";
import { RiCloseCircleLine } from "react-icons/ri";

function SingleProduct({ singleItem }) {
    const { handleIncrease, handleDecrease, handleRemove } = useContext(LayoutContext)
    return (
        <>
            <ImageCell imgSrc={singleItem?.image} imgAlt={singleItem.name} />
            <td><p className="product-name">{singleItem?.name}</p></td>
            <td>
                <div className="admin-order-quantity">
                    <div className="btn">
                        <button
                            type="button"
                            onClick={() => handleDecrease(singleItem.item)}
                        >
                            <FaMinus />
                        </button>
                    </div>

                    <span >
                        {singleItem?.quantity}
                    </span>
                    <div className="btn">
                        <button
                            type="button"
                            onClick={() => handleIncrease(singleItem.item)}
                        >
                            <FaPlus />
                        </button>
                    </div>

                </div>
            </td>
            <ShortTextCell text={takaFormatter(singleItem?.unitPrice)} />
            <ShortTextCell text={takaFormatter(singleItem?.total)} />


            <td>
                <div className="action_buttons_wrapper">


                    <div className="action_buttons">
                        <CRUDButton
                            handleClick={() => {
                                handleRemove(singleItem.item)

                            }}
                        >
                            <RiCloseCircleLine />
                        </CRUDButton>


                    </div>




                </div>
            </td>
        </>

    )
}
export default SingleProduct