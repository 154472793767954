import {  useEffect, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"
import { useGetInfo } from "antopolis-react-utils/hooks"

function UpdateDealerDivision({ api, setShowUpdateForm, targetID, triggerFetch }) {

    const [name, setName] = useState('')


    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()

    const { info: dealerDivision } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + 'singleDealerDivision/' + targetID,
    })

    useEffect(() => {
        if (dealerDivision) {
            setName(dealerDivision.name)
        }
    }, [dealerDivision])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('name', name)

        const response = await axiosInstance.patch(api + 'singleDealerDivision/' + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <ShortTextInput
                    label={`Name`}
                    value={name}
                    placeholder={`Enter Name`}
                    setState={setName}

                />
                <FormSubmitButton text="Update Dealer Division" />
            </Form>
        </div>
    )
}

export default UpdateDealerDivision