import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { SOCIAL_LINKS_API } from "../../../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs";
import { Image } from "../../../../Partials/Elements/Image/Image";

function ViewSocialLink({ targetID }) {
  const axiosInstance = useAxiosInstance();

  const { info: socialLink } = useGetInfo({
    axiosInstance: axiosInstance,
    api: SOCIAL_LINKS_API + "singleSocialLink/" + targetID,
  });

  console.log(socialLink);

  return (
    <div className="crud_view_content">
      {socialLink && (
        <>
          <h1>Social Link Details</h1>
          <div>
            <strong>Name:</strong> {socialLink.name}
          </div>
          <div>
            <strong>Link:</strong>{" "}
            <a href={socialLink.link} target="_blank" rel="noopener noreferrer">
              {socialLink.link}
            </a>
          </div>
          <div>
            <strong>Visibility:</strong>{" "}
            {socialLink.visibility ? "Visible" : "Hidden"}
          </div>
          <div>
            <h2>Image</h2>
            <Image
              imgLink={socialLink.image}
              imgAlt="Social Link Image"
              className=""
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ViewSocialLink;
