import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { COLLECTIONS_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";

function ViewCollection({ targetID }) {
  const axiosInstance = useAxiosInstance();

  const { info: collection } = useGetInfo({
    axiosInstance: axiosInstance,
    api: COLLECTIONS_API + "singleCollection/" + targetID,
  });

  return (
    <div className="crud_view_content">
      {collection && (
        <>
          <h1>Name</h1>
          <p>{collection.name}</p>

          <h1>Slug</h1>
          <p>{collection.slug}</p>

          <h1>Description</h1>
          <p>{collection.description}</p>


        </>
      )}
    </div>
  );
}

export default ViewCollection;
