import InputBoilerplate from "../InputBoilerplate"


function EmailInput({ label, value, placeholder, setState, setIsValid }) {
  const handleChange = (e) => {
    const newValue = e.target.value;
    setState(newValue);

    // Simple regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(newValue));
  };
  return (
    <div className="input_group">
      <label className="input_field_label caption bold">{label}</label>
      {/* <InputBoilerplate
        label={label}
        type={'email'}
        value={value}
        placeholder={placeholder}
        setState={setState}
      /> */}

      <input
        className='input_field'
        type="email"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </div>
  )
}

export default EmailInput 