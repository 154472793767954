import {  useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";

import { useUseOrientation } from "../../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { CreateButton } from "../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ImageCell } from "../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { EditButton, ViewButton } from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";

import ViewHeroSlider from "./ViewTestimonial";
import UpdateHeroSlider from "./UpdateTestimonial";
import CreateTestimonial from "./CreateTestimonial";

function Testimonial({  api }) {

  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);

  const { portrait } = useUseOrientation()
  const axiosInstance = useAxiosInstance();

  const { info: testimonials } = useGetInfo({
    axiosInstance: axiosInstance,
    api : api + '/getAllTestimonials',
    toggleFetch
  });

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <div className="screen_wrapper">
      <div className="screen_header" >
        <h1 className="screen_heading">
        Testimonials ({testimonials?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Testimonial"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable >
        <CRUDTableHeader>
          <CRUDth th="Image" />
          <CRUDth th="Name" />
          <CRUDth th="Designation" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {testimonials?.map((testimonial) => (
            <tr key={testimonial._id}>
              <ImageCell imgSrc={testimonial.image} />
              <td>{testimonial.name}</td>
              <td>{testimonial.designation}</td>
              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      if (targetID === testimonial._id) {
                        setTargetID("");
                      } else {
                        setShowActionButton(true);
                        setTargetID(testimonial._id);
                      }
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && ( 
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={testimonial._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={testimonial._id}
                        setTargetID={setTargetID}
                      />
                    </div>
                  )}
                  {portrait && showActionButton && targetID === testimonial._id && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={testimonial._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={testimonial._id}
                        setTargetID={setTargetID}
                        setShowActionButton={setShowActionButton}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>

      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Testimonial`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateTestimonial
            setShowCreateForm={setShowCreateForm}
            triggerFetch={triggerFetch}
            api={api}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Testimonial`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateHeroSlider
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
            api={api}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Testimonial`}
          setShowModalContent={setShowViewSection}
        >
          <ViewHeroSlider targetID={targetID} api={api} />
        </Modal>
      )}

    </div>
  );
}

export default Testimonial;
