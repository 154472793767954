export const LOCATION_API = 'https://backend.manchitro.club/api/locations/'

// https://backend.manchitro.club/api/locations/divisions
// https://backend.manchitro.club/api/locations/districts/getDistrictsFromDivision/:id
// https://backend.manchitro.club/api/locations/areas/getAreasFromDistrict/:id


export const ADDRESS_API ='addresses/'

export const DIVISIONS_API = LOCATION_API + 'divisions/'
export const DISTRICTS_API = LOCATION_API + 'districts/'
export const AREAS_API = LOCATION_API + 'areas/'


export const MANAGE_ADDRESS_API = ADDRESS_API + 'manageAddress/'
export const CREATE_ADDRESS_API = ADDRESS_API + 'setAddress/'
export const UPDATE_ADDRESS_API = ADDRESS_API + 'updateAddress/'
export const DELETE_ADDRESS_API = ADDRESS_API + 'deleteAddress/'
export const DEFAULT_ADDRESSES_API =ADDRESS_API+ 'defaultAddresses/'


// districtRoutes.route('/getDistrictsFromDivision/:id').get(getDistrictsFromDivision)