import { useGetInfo } from "antopolis-react-utils/hooks";

import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { DEALER_API } from "../../../../../Utilities/APIs/DealerDivisionAPIs/DealerDivisionAPIs";

function ViewDealer({ targetID }) {
  const axiosInstance = useAxiosInstance();

  const { info: dealer } = useGetInfo({
    axiosInstance: axiosInstance,
    api: DEALER_API + 'singleDealer/' + targetID,
})


  return (
    <div className="crud_view_content">
      {dealer && (
        <>
          <h1>Shop Name</h1>
          <p>{dealer.shopName}</p>
          <h1>Name</h1>
          <p>{dealer.name}</p>

          <h1>Email</h1>
          <p>{dealer.email}</p>

          <h1>Phone</h1>
          <p>{dealer.phone}</p>

          <h1>Address</h1>
          <p>{dealer.address}</p>

        
        </>
      )}
    </div>
  );
}

export default ViewDealer;
