import { FaCheck } from "react-icons/fa6";
function OrderSuccess({ orderId }) {
    return (
        <div className="order-success">
            <div className="image">
                <FaCheck />
            </div>
            <p >
                Your order has been confirmed!!!
            </p>
            <p >
                Thank you for ordering from{" "}
                <span >Gasdum</span>
            </p>
            <p>
                Order Code: <span >#{orderId}</span>
            </p>
        </div>)
}
export default OrderSuccess;