import React, { useEffect, useState } from 'react'
import { IconThreeDots } from "antopolis-react-icons";
import { CreateButton } from '../../../../Partials/Layout/CRUD/CreateButton/CreateButton'
import FilterSelect from '../../../../Partials/Forms/FilterSelect/FilterSelect'
import BackButton from '../../../../Partials/Elements/BackButton/BackButton'
import { useAxiosInstance } from '../../../../../Utilities/Hooks/useAxiosInstance'
import { CRUDTable } from '../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable'
import { CRUDTableHeader } from '../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader'
import { CRUDth } from '../../../../Partials/Layout/CRUD/CRUDth/CRUDth'
import { ArchiveButton, EditButton, ViewButton } from '../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons'
import { useUseOrientation } from '../../../../../Utilities/Hooks/useUseOrientation';
import { useGetInfo } from 'antopolis-react-utils/hooks';
import { Modal } from '../../../../Partials/Elements/Modal/Modal';
import ArchiveItem from '../../../../Partials/Layout/ArchiveItem/ArchiveItem';
import { DEALER_API } from '../../../../../Utilities/APIs/DealerDivisionAPIs/DealerDivisionAPIs';
import { ShortTextCell } from '../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell';
import { useParams } from 'react-router-dom';
import CreateDealer from './CreateDealer';
import UpdateDealer from './UpdateDealer';
import ViewDealer from './ViewDealer';
// import CreateDealerArea from './CreateDealerArea';
// import UpdateDealerArea from './UpdateDealerArea';


function Dealers() {

    const [showModal, setShowModal] = useState(false);
    const [showActionButton, setShowActionButton] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showArchiveSection, setShowArchiveSection] = useState(false);
    const [showViewSection, setShowViewSection] = useState(false);


    const [filter, setFilter] = useState("active");
    const [toggleFetch, setToggleFetch] = useState(false);
    const { id } = useParams();

    const axiosInstance = useAxiosInstance();

    const [targetID, setTargetID] = useState(null);
    const { portrait } = useUseOrientation()

    const [isActive, setIsActive] = useState(null)

    const { info: dealers } = useGetInfo({
        axiosInstance: axiosInstance,
        api: DEALER_API + "getDealersByDealerArea/" + id + `?filter=${filter}`,
        toggleFetch
    });

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <div className="screen_wrapper">
            <div className="screen_header" >
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton />
                    <h1 className="screen_heading">{`Dealer (${dealers?.length} in total)`}</h1>
                </div>
                <FilterSelect filter={filter} setFilter={setFilter} />
                <CreateButton
                    screenTopicSingular={"Dealer"}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable >
                <CRUDTableHeader>
                    <CRUDth th="Shop Name" />
                    <CRUDth th="Name" />
                    <CRUDth th="Email" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {dealers?.map((dealer) => (
                        <tr key={dealer._id}>
                            <ShortTextCell text={dealer.shopName} />
                            <ShortTextCell text={dealer.name} />
                            <ShortTextCell text={dealer.email} />
                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === dealer._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(dealer._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={dealer._id}
                                                setTargetID={setTargetID}
                                            />
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={dealer._id}
                                                setTargetID={setTargetID}
                                            />
                                            <ArchiveButton
                                                setShowArchiveForm={setShowArchiveSection}
                                                targetID={dealer._id}
                                                setTargetID={setTargetID}
                                                isActive={dealer?.isActive}
                                                setActive={setIsActive} />
                                        </div>

                                    )}

                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Dealer `}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateDealer
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}
                        dealerAreaID={id}

                    />
                </Modal>
            )}
            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Dealer`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateDealer
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                        dealerAreaID={id}

                    />

                </Modal>
            )}
            {showArchiveSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Archive Dealer Area`}
                    setShowModalContent={setShowArchiveSection}
                >
                    <ArchiveItem
                        api={`${DEALER_API}archiveDealer/`}
                        isActive={isActive}
                        targetID={targetID}
                        setShowModal={setShowModal}
                        setShowActionButton={setShowArchiveSection}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}

            {
                showViewSection && (
                    <Modal
                        extraClass={"small"}
                        modalHeading={`View Dealer`}
                        setShowModalContent={setShowViewSection}
                    >
                        <ViewDealer targetID={targetID} />
                    </Modal>
                )
            }

        </div>

    )
}

export default Dealers
