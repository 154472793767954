import { useContext, useEffect, useState } from "react";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton"
import SearchSelectField from "../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { CATEGORIES_API, MANAGE_ITEMS_API, SUBCATEGORIES } from "../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../../Contexts/GlobalContexts/LayoutContext";
import { useGetInfo } from "antopolis-react-utils/hooks";
import "./adminOrder.css"
import SingleProduct from "./SingleProduct";
import { takaFormatter } from "../../../../../Utilities/Hooks/TakaFormatter";
import CustomerInfo from "./CustomerInfo";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";
import OrderSuccess from "./OrderSuccess";
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";

function AdminOrder() {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [fullAddress, setFullAddress] = useState("");
    const [division, setDivision] = useState("");
    const [district, setDistrict] = useState(null);
    const [area, setArea] = useState("");
    const [notes, setNotes] = useState("");
    const [tab, setTab] = useState(1)
    const [allItems, setAllItems] = useState([])
    const [type, setType] = useState("Bathware");
    const [categories, setCategories] = useState(null)
    const [category, setCategory] = useState(null)
    const [subCategories, setSubCategories] = useState(null)
    const [subCategory, setSubCategory] = useState(null)
    const [item, setItem] = useState(null)
    const axiosInstance = useAxiosInstance();
    const [showModal, setShowModal] = useState(false)
    const [orderId, setOrderId] = useState("")
    const [isError, setIsError] = useState(false);

    const { toggleFetch, triggerFetch, cartItems, setCartItems, addToCart, total, discount, vat, deliveryFee, subtotal, grandTotal, promoCode, setPromoCode, handleApplyPromoCode } = useContext(LayoutContext);


    const typeDatas = [
        {
            name: "Bathware",
            value: "Bathware"
        },
        {
            name: "Kitchenware",
            value: "Kitchenware"
        }
    ]

    useEffect(() => {
        const hendleGetCategoryByType = async () => {

            const response = await axiosInstance.get(`${CATEGORIES_API}getAllCategories?type=${type}`)

            if (response) {
                setCategories(response?.data)

            }


        }
        hendleGetCategoryByType()

    }, [type])

    useEffect(() => {
        const hendleGetSubCategoriesByCategory = async () => {

            const response = await axiosInstance.get(`${SUBCATEGORIES}singleSubCategoriesByCategory/${category}`)

            if (response) {
                setSubCategories(response?.data)

            }


        }
        category && hendleGetSubCategoriesByCategory()

    }, [category])

    useEffect(() => {
        const hendleGetItemsByCategory = async () => {

            const response = await axiosInstance.get(`adminOrder/getItemsByCategory?type=${type}&category=${category !== null ? category : ""}&subCategory=${subCategory !== null ? subCategory : ""}`)

            if (response) {
                console.log("category", category, response)
                setAllItems(response?.data)

            }


        }
        hendleGetItemsByCategory()

    }, [type, category, subCategory])
    useEffect(() => {
        const hendleSingleItem = async () => {
            if (item !== null) {
                const response = await axiosInstance.get(`adminOrder/getSingleItem/${item}`)
                if (response) {
                    triggerFetch()
                    // setShowCreateForm(false)
                    const product = response?.data
                    console.log("response...", response)
                    addToCart(product._id, product.price, product.name, product.image, product?.isDiscount, product?.discount?.amount)
                }
            }

        }
        hendleSingleItem()

    }, [item])

    const handleAdminOrder = async () => {

        if (phoneNumber?.length === 11 && isEmailValid === true) {
            setIsError(false)
            const order = {
                name: name,
                phoneNumber: phoneNumber,
                email: email,
                fullAddressString: fullAddress,
                division: division?.name,
                district: district?.name,
                area: area,
                notes: notes,
                cartItems: cartItems,
                deliveryFee,
                total,
                subtotal,
                vat,
                grandTotal,
                promoCode,
                discount,
                isAdmin: true
            };
            const response = await axiosInstance.post(
                "adminOrder/orders",
                order
            );

            if (response.status === 200) {
                localStorage.removeItem("cart");
                setCartItems([])
                setOrderId(response.data?.order?._id)
                setTab(1)
                setShowModal(true)
            }
        } else {
            setIsError(true)
        }
    }
    return (<div className="screen_wrapper">
        <div className="screen_header admin-order" >
            {/* <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <BackButton />
                <h1 className="screen_heading">Order by Admin</h1>
            </div> */}
            {tab === 1 && <div className="admin-order-product-select">
                {/* <SelectInput
                    label="Select Type"
                    value={type}
                    setState={setType}
                >
                    <option value="" hidden>Select Type</option>
                    <option value="Bathware">Bathware</option>
                    <option value="Kitchenware">Kitchenware</option>
                </SelectInput> */}
                <SearchSelectField
                    label="Select Type"
                    optionLabel="name"
                    optionValue="value"
                    value={type}
                    options={typeDatas}
                    placeholder="Select Type"
                    setState={setType}
                />
                <SearchSelectField
                    label="Categories"
                    optionLabel="name"
                    optionValue="_id"
                    value={category}
                    options={categories}
                    placeholder="Enter Category"
                    setState={setCategory}
                />
                {category !== null && <SearchSelectField
                    label="Sub Categories"
                    optionLabel="name"
                    optionValue="_id"
                    value={subCategory}
                    options={subCategories}
                    placeholder="Enter sub categories"
                    setState={setSubCategory}
                />}
                <SearchSelectField
                    label="Products"
                    optionLabel="name"
                    optionValue="_id"
                    value={item}
                    options={allItems}
                    placeholder="Enter Product"
                    setState={setItem}
                />
            </div>}

        </div>
        <CRUDTable >
            {tab === 1 && <><CRUDTableHeader>
                <CRUDth th="image" />
                <CRUDth th="Name" />
                <CRUDth th="Quantity" />
                <CRUDth th="Unit Price" />
                <CRUDth th="Total Price" />
                <CRUDth th="Actions" />
            </CRUDTableHeader>
                <tbody>
                    {cartItems?.length > 0 && cartItems?.map((singleItem) => (
                        <tr key={singleItem._id}>

                            <SingleProduct singleItem={singleItem} />

                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total: {takaFormatter(total)} </td>
                        <td></td>
                        <td>
                            <button className="admin-order-button" onClick={() => setTab(2)}>
                                Continue
                            </button>
                        </td>
                    </tr>
                </tfoot></>
            }
            {tab === 2 && <>
                <CRUDTableHeader>
                    {/* <CRUDth th="image" />
                    <CRUDth th="Name" />
                    <CRUDth th="Quantity" />
                    <CRUDth th="Unit Price" />
                    <CRUDth th="Total Price" />
                    <CRUDth th="Actions" /> */}

                </CRUDTableHeader>
                <tbody>
                    <div className="admin-order-details">
                        <div className="customer-info">
                            {isError && <h1 className="error-text">Please input valid data</h1>}
                            <CustomerInfo
                                name={name}
                                setName={setName}
                                email={email}
                                setEmail={setEmail}
                                setIsValid={setIsEmailValid}
                                phoneNumber={phoneNumber}
                                setPhoneNumber={setPhoneNumber}
                                fullAddress={fullAddress}
                                setFullAddress={setFullAddress}
                                division={division}
                                setDivision={setDivision}
                                district={district}
                                setDistrict={setDistrict}
                                area={area}
                                setArea={setArea}
                                notes={notes}
                                setNotes={setNotes}
                            />

                        </div>
                        <div className="order">
                            <div className="summary">
                                <div className="promo-code">
                                    {/* <ShortTextInput
                                        // label="Division"
                                        value={promoCode}
                                        placeholder="Enter Promo Code"
                                        setState={setPromoCode}
                                    />
                                    <button className="promo-code-button" onClick={() => handleApplyPromoCode()}>Apply</button> */}
                                </div>
                                <div className="order-summary">
                                    <span className="">TOTAL</span>
                                    <span className="">{takaFormatter(total)}</span>
                                </div>
                                <div className="order-summary">
                                    <span className="">DISCOUNT</span>
                                    <span className="">{takaFormatter(discount)}</span>
                                </div>
                                <div className="order-summary">
                                    <span className="">VAT</span>
                                    <span className="">{takaFormatter(vat)}</span>
                                </div>
                                <div className="order-summary">
                                    <span className="">DELIVERY FEE</span>
                                    <span className="">{takaFormatter(deliveryFee)}</span>
                                </div>
                                <div className="order-summary">
                                    <span className="">SUB-TOTAL</span>
                                    <span className="">{takaFormatter(subtotal)}</span>
                                </div>

                                <div className="order-summary">
                                    <span className="">GRAND TOTAL</span>
                                    <span className="">{takaFormatter(grandTotal)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <button className="admin-order-button" onClick={() => setTab(1)}>
                                Previous
                            </button>
                        </td>
                        <td></td>
                        <td></td>
                        <td> </td>
                        <td></td>
                        <td>
                            <button className="admin-order-button" onClick={() => handleAdminOrder()}>
                                Order Confirm
                            </button>
                        </td>
                    </tr>
                </tfoot>
            </>}

        </CRUDTable>
        {showModal && (
            <Modal
                extraClass={"small"}
                modalHeading={``}
                setShowModalContent={setShowModal}
            >
                <OrderSuccess
                    setShowUpdateForm={setShowModal}
                    orderId={orderId}
                />
            </Modal>
        )}
    </div>)
}
export default AdminOrder