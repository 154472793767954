import { useContext, useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";

import CreateCategory from "./CreateCategory";
import UpdateCategory from "./UpdateCategory";
import ViewCategory from "./ViewCategory";

import { CATEGORIES_API } from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { useUseOrientation } from "../../../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import { CreateButton } from "../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ImageCell } from "../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import {
  CRUDButton,
  EditButton,
  ViewButton,
} from "../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import { RiImage2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

function Category() {
  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);

  const [targetID, setTargetID] = useState(null);
  const navigate = useNavigate();

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();
  const { toggleFetch } = useContext(LayoutContext);

  const { info: categories } = useGetInfo({
    axiosInstance: axiosInstance,
    api: CATEGORIES_API + "getAllCategories",
    toggleFetch,
  });

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">
          Categories ({categories?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Category"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Slug" />
          <CRUDth th="Precedence" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {categories?.map((category) => (
            <tr key={category._id}>
              <td>{category.name}</td>
              <td>{category.slug}</td>
              <td>{category.precedence}</td>
              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      if (targetID === category._id) {
                        setTargetID("");
                      } else {
                        setShowActionButton(true);
                        setTargetID(category._id);
                      }
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={category._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={category._id}
                        setTargetID={setTargetID}
                      />
                      <CRUDButton
                        handleClick={() => {
                          navigate(`/main/subCategories/${category._id}`);
                        }}
                      >
                        <RiImage2Line />
                      </CRUDButton>
                    </div>
                  )}
                  {portrait &&
                    showActionButton &&
                    targetID === category._id && (
                      <div className="action_buttons">
                        <ViewButton
                          setShowViewSection={setShowViewSection}
                          targetID={category._id}
                          setTargetID={setTargetID}
                        />
                        <EditButton
                          setShowUpdateForm={setShowUpdateForm}
                          targetID={category._id}
                          setTargetID={setTargetID}
                          setShowActionButton={setShowActionButton}
                        />
                        <CRUDButton
                        handleClick={() => {
                          navigate(`/main/subCategories/${category._id}`);
                        }}
                      >
                        <RiImage2Line />
                      </CRUDButton>
                      </div>
                    )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>

        {/* <Pagination
          totalPages={totalPages}
          totalItems={totalClients}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        /> */}
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Category`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateCategory setShowCreateForm={setShowCreateForm} />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Category`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateCategory
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
          />
        </Modal>
      )}
      {/* 

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Client`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateClient
            employee={employee}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Client`}
          setShowModalContent={setShowViewSection}
        >
          <ViewClient triggerFetch={triggerFetch} targetID={targetID} />
        </Modal>
      )} */}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Category`}
          setShowModalContent={setShowViewSection}
        >
          <ViewCategory targetID={targetID} />
        </Modal>
      )}
    </div>
  );
}

export default Category;
