import React, { useEffect, useState } from 'react'
import { IconThreeDots } from "antopolis-react-icons";
import { CreateButton } from '../../../../Partials/Layout/CRUD/CreateButton/CreateButton'
import FilterSelect from '../../../../Partials/Forms/FilterSelect/FilterSelect'
import BackButton from '../../../../Partials/Elements/BackButton/BackButton'
import { useAxiosInstance } from '../../../../../Utilities/Hooks/useAxiosInstance'
import { CRUDTable } from '../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable'
import { CRUDTableHeader } from '../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader'
import { CRUDth } from '../../../../Partials/Layout/CRUD/CRUDth/CRUDth'
import { ArchiveButton, EditButton, ViewButton } from '../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons'
import { useUseOrientation } from '../../../../../Utilities/Hooks/useUseOrientation';
import { useGetInfo } from 'antopolis-react-utils/hooks';
import { Modal } from '../../../../Partials/Elements/Modal/Modal';
import ArchiveItem from '../../../../Partials/Layout/ArchiveItem/ArchiveItem';
import UpdateDeliveryFee from './UpdateDeliveryFee';
import CreateDeliveryFee from './CreateDeliveryFee';

function DeliveryFee({ api }) {
    const [showModal, setShowModal] = useState(false);
    const [showActionButton, setShowActionButton] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showArchiveSection, setShowArchiveSection] = useState(false);
    const [filter, setFilter] = useState("active");
    const [toggleFetch, setToggleFetch] = useState(false);
    const axiosInstance = useAxiosInstance();
    const [targetID, setTargetID] = useState(null);
    const { portrait } = useUseOrientation()
    const [isActive, setIsActive] = useState(null)

    const { info: deliveryFees } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + "getAllDeliveryFees"+`?filter=${filter}`,
        toggleFetch
    });

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <div className="screen_wrapper">
            <div className="screen_header" >
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton />
                    <h1 className="screen_heading">{`Delivery Fee (${deliveryFees?.length} in total)`}</h1>
                </div>
                <FilterSelect filter={filter} setFilter={setFilter} />
                <CreateButton
                    screenTopicSingular={"Delivery Fee"}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable >
                <CRUDTableHeader>
                    <CRUDth th="Name" />
                    <CRUDth th="Amount" />
                    <CRUDth th="IsApplicable" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {deliveryFees?.map((deliveryFee) => (
                        <tr key={deliveryFee._id}>
                            <td>{deliveryFee.name}</td>
                            <td>{deliveryFee.amount}</td>
                            <td>{deliveryFee.isApplicable ? "True" : "False"}</td>
                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === deliveryFee._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(deliveryFee._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            {/* <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={vat._id}
                                                setTargetID={setTargetID}
                                            /> */}
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={deliveryFee._id}
                                                setTargetID={setTargetID}
                                            />
                                            <ArchiveButton
                                                setShowArchiveForm={setShowArchiveSection}
                                                targetID={deliveryFee._id}
                                                setTargetID={setTargetID}
                                                isActive={deliveryFee?.isActive}
                                                setActive={setIsActive} />
                                        </div>

                                    )}

                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Promo Code`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateDeliveryFee
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}
                        api={api}
                    />
                </Modal>
            )}
            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Promo Code`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateDeliveryFee
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                        api={api}
                    />

                </Modal>
            )}
            {showArchiveSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Archive Promo Code`}
                    setShowModalContent={setShowArchiveSection}
                >
                    <ArchiveItem
                        api={`${api}archiveDeliveryFee/`}
                        isActive={isActive}
                        targetID={targetID}
                        setShowModal={setShowModal}
                        setShowActionButton={setShowArchiveSection}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}

        </div>

    )
}

export default DeliveryFee
