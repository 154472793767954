import {  useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";

import { useUseOrientation } from "../../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { CreateButton } from "../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ImageCell } from "../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { EditButton, ViewButton } from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";

import ViewHeroSlider from "./ViewHeroSlider";
import CreateHeroSlider from "./CreateHeroSlider";
import UpdateHeroSlider from "./UpdateHeroSlider";

function HeroSlider({  api }) {

  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();

  const { info: heroSliders } = useGetInfo({
    axiosInstance: axiosInstance,
    api:api + '/getAllHeroSliders',
    toggleFetch
  });

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <div className="screen_wrapper">
      <div className="screen_header" >
        <h1 className="screen_heading">
          Hero Sliders ({heroSliders?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Hero Slider"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable >
        <CRUDTableHeader>
          <CRUDth th="Image" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {heroSliders?.map((heroSlider) => (
            <tr key={heroSlider._id}>
              <ImageCell imgSrc={heroSlider.image} />
              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      setShowActionButton(true);
                      setTargetID(heroSlider._id);
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={heroSlider._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={heroSlider._id}
                        setTargetID={setTargetID}
                      />
                    </div>
                  )}
                  {portrait && showActionButton && targetID === heroSlider._id && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={heroSlider._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={heroSlider._id}
                        setTargetID={setTargetID}
                        setShowActionButton={setShowActionButton}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>

      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Hero Slider`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateHeroSlider
            setShowCreateForm={setShowCreateForm}
            triggerFetch={triggerFetch}
            api={api}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Hero Slider`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateHeroSlider
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
            api={api}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Hero Slider`}
          setShowModalContent={setShowViewSection}
        >
          <ViewHeroSlider targetID={targetID} api={api} />
        </Modal>
      )}

    </div>
  );
}

export default HeroSlider;
