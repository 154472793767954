import { useContext, useState } from "react";

import { SUBCATEGORIES } from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Forms/Form";
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import slugify from "react-slugify";

function CreateSubcategory({ setShowCreateForm, categoryID }) {

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [error, setError] = useState("");

  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();


    const itemData = new FormData();
    itemData.append("category", categoryID);
    itemData.append("name", name);
    itemData.append("slug", slugify(name));
    itemData.append("description", description);
    // itemData.append("precedence", precedence);

    try {
      const response = await axiosInstance.post(
        SUBCATEGORIES + "createSubCategory",
        itemData
      );
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
      }
    } catch (error) {
      setError(
        "An error occurred while creating the Subcategory. Please try again."
      );
    }
  }

  return (
    <div className="create_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>

        <ShortTextInput
          label="Name"
          value={name}
          placeholder="Enter Name"
          setState={setName}
        />
        <ShortTextInput
          label="Description"
          value={description}
          placeholder="Enter Description"
          setState={setDescription}
        />

        {/* <NumberInput
          label="Precedence"
          value={precedence}
          placeholder="Enter Precedence"
          setState={setPrecedence}
        /> */}

        <FormSubmitButton text="Create Subcategory" />
      </Form>
    </div>
  );
}

export default CreateSubcategory;
