import { useContext, useState } from "react";
import slugify from 'react-slugify';

import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { COLLECTIONS_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import ShortTextInput from "../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../../../../Partials/Forms/Form";
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";



function CreateCollection({ setShowCreateForm }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = {
      name,
      description,
      slug : slugify(name),
    };

    try {
      const response = await axiosInstance.post(
        COLLECTIONS_API + "createCollection",
        itemData
      );
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
      }
    } catch (error) {
      setError(
        "An error occurred while creating the collection. Please try again."
      );
    }
  }

  return (
    <div className="create_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label="Name"
          value={name}
          placeholder="Enter Name"
          setState={setName}
        />
        <ShortTextInput
          label="Description"
          value={description}
          placeholder="Enter Description"
          setState={setDescription}
        />
        <ShortTextInput
          label="Slug"
          value={slugify(name)}
          placeholder="Enter Slug"

        />

        <FormSubmitButton text="Create Collection" />
      </Form>
    </div>
  );
}

export default CreateCollection;
