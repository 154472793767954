import { useNavigate } from "react-router-dom";
import { IconThreeDots } from "antopolis-react-icons";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { useUseOrientation } from "../../../../Utilities/Hooks/useUseOrientation";
import { useGetInfo } from "antopolis-react-utils/hooks";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import { CRUDTable } from "../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { ShortTextCell } from "../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { Modal } from "../../../Partials/Elements/Modal/Modal";
import ViewOrder from "../Orders/OrdersFromStatus/ViewOrder";
import { useEffect, useState } from "react";
import { CRUDth } from "../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { CRUDButton, ViewButton } from "../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { RiListSettingsLine } from "react-icons/ri";
import { ORDERS_API } from "../../../../Utilities/APIs/OrderAPIs/OrderAPIs";
import { REPORTS_API } from "../../../../Utilities/APIs/ReportAPIs/ReportAPIs";
import DateInput from "../../../Partials/Forms/FormInputs/DateInput/DateInput";
import { takaFormatter } from "../../../../Utilities/Hooks/TakaFormatter";

import "./SaleReports.css";

function SaleReports() {

    const [showActionButton, setShowActionButton] = useState(false);
    const [showViewSection, setShowViewSection] = useState(false);


    const [orders, setOrders] = useState(null)
    const [limit, setLimit] = useState(100);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totalOrderPrice, setTotalOrderPrice] = useState(0);
    const [oneday, setOneday] = useState("");

    const [totalItems, setTotalItems] = useState(0);


    const [targetID, setTargetID] = useState(null);

    const { portrait } = useUseOrientation()
    const axiosInstance = useAxiosInstance();
    const navigate = useNavigate();


    useEffect(() => {
        setStartDate(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDay() < 10 ? '0' + new Date().getDay() : new Date().getDay()}`)
        setEndDate(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDay() < 10 ? '0' + new Date().getDay() : new Date().getDay()}`)
    }, [])

    useEffect(() => {
        async function fetchAndSetSaleReports() {
            const res = await axiosInstance.get(
                `${REPORTS_API}getOrderSaleReports?startDate=${startDate}&endDate=${endDate}&oneday=${oneday}`
            );

            setOrders(res?.data?.orders);
            setTotalItems(res?.data?.totalItems);
            const formatNumber = new Intl.NumberFormat("en-IN").format(
                parseInt(res?.data?.totalOrderAmount)
            );

            setTotalOrderPrice(formatNumber);
        }
        fetchAndSetSaleReports();
    }, [

        axiosInstance,
        startDate,
        endDate,
        limit,
        oneday
    ]);



    return (
        <div className="screen_wrapper reports">
            <div className="screen_header" >
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    {/* <BackButton /> */}
                    <h1 className="screen_heading"> Orders   ({totalItems} in total)</h1>
                </div>
                <DateInput
                    label={"Start Date"}
                    value={startDate}
                    setState={setStartDate}
                />

                <DateInput label={"End Date"} value={endDate} setState={setEndDate} />
                <DateInput label={"One Day"} value={oneday} setState={setOneday} />

                <h1 className="screen_heading">
                    Total Order {totalOrderPrice} TK
                </h1>
            </div>
            <CRUDTable >
                <CRUDTableHeader>
                    <CRUDth th="Order ID" />
                    <CRUDth th="Customer Name" />
                    <CRUDth th="Order Date" />
                    <CRUDth th="Order Total" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {orders?.length > 0 && orders?.map((order) => (
                        <tr key={order._id}>
                            <ShortTextCell text={order?._id} />
                            <ShortTextCell text={order?.name ? order?.name : order?.customer?.name} />
                            <ShortTextCell text={new Date(order?.createdAt).toLocaleDateString()} />
                            <ShortTextCell text={takaFormatter(order?.grandTotal)} />

                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === order._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(order._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={order._id}
                                                setTargetID={setTargetID}
                                            />

                                            <CRUDButton handleClick={() => navigate('/main/orderItemsFromOrder/' + order._id)}>
                                                <RiListSettingsLine />
                                            </CRUDButton>

                                        </div>


                                    )}
                                    {portrait && showActionButton && targetID === order._id && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={order._id}
                                                setTargetID={setTargetID}
                                            />

                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </CRUDTable>
            {
                showViewSection &&
                <Modal
                    extraClass={"small"}
                    modalHeading={`View Order`}
                    setShowModalContent={setShowViewSection}
                >
                    <ViewOrder targetID={targetID} />
                </Modal>
            }


        </div>
    );
}

export default SaleReports;
