import './StatsCard.css';

const StatsCard = ({ title, value, icon }) => {
  return (
    <div className="stats-card">
      <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"10px"}}>
        <img src={icon} alt='' />
        <h3>{title}</h3>
      </div>
      <div>

        <p>{value === "undefined" || value === '৳ NaN' ? 0 : value}</p>
      </div>

    </div>
  );
};

export default StatsCard;
