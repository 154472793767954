import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import {
  COLLECTIONS_API,
  CONTROL_ITEMS_API,
  ITEM_COLLECTIONS_API,
} from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import ShortTextInput from "../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../../../../Partials/Forms/Form";
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useGetInfo } from "antopolis-react-utils/hooks";
import SearchSelectField from "../../../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField";
import NumberInput from "../../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";

function UpdateItemCollection({ setShowUpdateForm, targetID, collectionID }) {
  const [item, setItem] = useState("");
  const [error, setError] = useState("");

  const { triggerFetch, toggleFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  const { info: itemCollection } = useGetInfo({
    axiosInstance: axiosInstance,
    api: ITEM_COLLECTIONS_API + "singleItemCollection/" + targetID,
  });

  useEffect(() => {
    setItem(itemCollection?.item._id);
  }, [itemCollection]);

  const { info: items } = useGetInfo({
    axiosInstance: axiosInstance,
    api: CONTROL_ITEMS_API + "getAllItems",
    toggleFetch,
  });
console.log(items, 'items')
  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = {
      item: item,
      collectionID: collectionID,
    };

    try {
      const response = await axiosInstance.patch(
        `${ITEM_COLLECTIONS_API}singleItemCollection/${targetID}`,
        itemData
      );
      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
      }
    } catch (error) {
      setError(
        "An error occurred while updating the item collection. Please try again."
      );
    }
  }

  return (
    <div className="update_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <SearchSelectField
          label="Items"
          optionLabel="name"
          optionValue="_id"
          value={item}
          options={items}
          placeholder="Select Item"
          setState={setItem}
        />
      <div className="input_group" style={{height: '300px'}}>

      </div>

        <FormSubmitButton text="Update Item Collection" />
      </Form>
    </div>
  );
}

export default UpdateItemCollection;
