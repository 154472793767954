import React, { useEffect, useState } from 'react';
import { Chart } from 'react-charts';
import { curveMonotoneX } from 'd3-shape';
import { useAxiosInstance } from '../../../../../Utilities/Hooks/useAxiosInstance';
import { useGetInfo } from 'antopolis-react-utils/hooks';
import SearchSelectField from '../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField';
import "./salesChart.css"

const SalesChart = () => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [monthlySalesReport, setMonthlySalesReport] = useState([])
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        const hendleGetMonthlySalesReport = async () => {

            const response = await axiosInstance.get(`stats/getMonthlySalesReportByYear/${selectedYear}`)

            if (response) {
                setMonthlySalesReport(response?.data)

            }


        }
        selectedYear && hendleGetMonthlySalesReport()

    }, [selectedYear])
    const data = [
        {
            label: 'Monthly Sales',
            data: monthlySalesReport?.formattedData?.length > 0 ? monthlySalesReport?.formattedData : []
        }
    ];

    const axes = [
        {
            primary: true,
            type: 'ordinal',
            position: 'bottom',
            showGrid: false,
            showTicks: true,
            getOptions: () => ({
                tickLabelProps: () => ({
                    fill: 'white',  // Month name color (x-axis)
                    fontSize: 14,  // Adjust font size if needed
                }),
            }),
        },
        {
            type: 'linear',
            position: 'left',
            showGrid: true,
            showTicks: true,
            getOptions: () => ({
                tickLabelProps: () => ({
                    fill: "white",  // Amount label color (y-axis)
                    fontSize: 14,  // Adjust font size if needed
                }),
            }),
        }
    ];

    const series = {
        type: 'line',
        curve: curveMonotoneX,  // D3 curve for smooth lines
    };

    const getSeriesStyle = () => ({
        stroke: 'black',  // Line color
        strokeWidth: 2,
    });

    const getDatumStyle = () => ({
        circle: {
            r: 5,  // Adjust the size of the data point
            stroke: 'black',
            fill: 'black',
        },
    });
    function generateArrayOfYears() {
        var max = new Date().getFullYear()
        var min = max - 70
        var years = []

        for (var i = max; i >= min; i--) {
            years.push(i)
        }
        return years
    }

    var years = generateArrayOfYears();
    const formattedOptions = years?.map(option => ({
        label: option,
        value: option,
    })) || [];
    console.log("formattedOptions", monthlySalesReport?.formattedData,selectedYear)
    return (
        <>
            <div className='monthly-sales-report'>
                <p>Monthly sales report for {selectedYear} </p>
                <SearchSelectField
                    label="Years"
                    optionLabel="label"
                    optionValue="value"
                    value={selectedYear}
                    options={formattedOptions}
                    placeholder="Select Year"
                    setState={setSelectedYear}
                />
            </div>
            <div
                style={{
                    width: '98%',
                    height: '300px',
                    backgroundColor: '#fff',
                    padding: '10px',
                    borderRadius: '10px',
                    color: "#fff",
                    margin:"auto"
                }}
            >
                <Chart
                    data={data}
                    axes={axes}
                    series={series}
                    getSeriesStyle={getSeriesStyle}
                    getDatumStyle={getDatumStyle}
                    tooltip
                />
            </div></>

    );
};

export default SalesChart;
