import { useContext, useEffect, useState } from "react";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { SOCIAL_LINKS_API } from "../../../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs";
import { LayoutContext } from "../../../../../Contexts/GlobalContexts/LayoutContext";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import Form from "../../../../Partials/Forms/Form";
import { useGetInfo } from "antopolis-react-utils/hooks";
import SwitchInput from "../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";

function UpdateSocialLink({ setShowUpdateForm, targetID }) {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [visibility, setVisibility] = useState(true);
  const [error, setError] = useState("");

  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  const { info: socialLink } = useGetInfo({
    axiosInstance: axiosInstance,
    api: SOCIAL_LINKS_API + "singleSocialLink/" + targetID,
  });

  useEffect(() => {
    if (socialLink) {
      setName(socialLink.name);
      setLink(socialLink.link);
      setVisibility(socialLink.visibility);
    }
  }, [socialLink]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = {
      name,
      link,
      visibility,
    };

    try {
      const response = await axiosInstance.patch(
        SOCIAL_LINKS_API + "singleSocialLink/" + targetID,
        itemData
      );
      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
      }
    } catch (error) {
      setError(
        "An error occurred while updating the social link. Please try again."
      );
    }
  }

  return (
    <div className="crate_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label="Name"
          value={name}
          placeholder="Enter Name"
          setState={setName}
        />
        <ShortTextInput
          label="Link"
          value={link}
          placeholder="Enter Link"
          setState={setLink}
        />
        <SwitchInput
          label="Visibility"
          toggleSwitch={() => setVisibility(!visibility)}
          checked={visibility}
        />
        <FormSubmitButton text="Update Social Link" />
      </Form>
    </div>
  );
}

export default UpdateSocialLink;
