import React from 'react'

import { RiGroup2Line, RiGroupLine, RiPercentFill, RiPriceTag2Fill, RiTruckFill } from 'react-icons/ri'
import { GiTicket } from "react-icons/gi";
import { ScreenHolder } from '../../../Partials/Layout/ScreenHolder/ScreenHolder';
import { NavCardList } from '../../../Partials/Layout/NavCardList/NavCardList';
import { NavCard } from '../../../Partials/Layout/NavCardList/NavCard/NavCard';

function Users() {
    return (
        <ScreenHolder>
            <NavCardList numOfCards={'two'} >
                <NavCard cardName={'Customers'} navCardLink='/main/customers' ><RiGroup2Line /></NavCard>
                <NavCard cardName={'Employees'} navCardLink='/main/employeeScreen' ><RiGroupLine /></NavCard>
            </NavCardList>
        </ScreenHolder>
    )
}

export default Users
