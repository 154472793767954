import { useContext } from "react"
import { NavLink } from "react-router-dom"

import './SidebarItem.css'
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import SidebarContext from "../../SidebarContext";

export function SidebarItem({  children, title }) {
   
    const { setShowSidebar } = useContext(SidebarContext)
    const {expandSidebar}= useContext(LayoutContext)
    return (
        <div className="item"  onClick={() => setShowSidebar(false)}>

            <NavLink to={
                '/main/' +
                (
                    title.includes(' ')
                    ?
                    title.replace(/\s/g, '')
                    :
                    title
                )
            } >
                {children}
                {expandSidebar &&
                    <span>{title}</span>
                }
            </NavLink>
        </div>
    )
}