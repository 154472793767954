import React from 'react'
import { ScreenHolder } from '../../../Partials/Layout/ScreenHolder/ScreenHolder'
import { NavCardList } from '../../../Partials/Layout/NavCardList/NavCardList'
import { NavCard } from '../../../Partials/Layout/NavCardList/NavCard/NavCard'
import { RiPercentFill, RiPriceTag2Fill, RiTruckFill,RiColorFilterLine } from 'react-icons/ri'
import { GiTicket } from "react-icons/gi";

function EcommercesSettings() {
    return (
        <ScreenHolder>
            <NavCardList numOfCards={'six'} >
                <NavCard cardName={'VAT'} navCardLink='/main/vat' ><RiPercentFill /></NavCard>
                <NavCard cardName={'Discounts'} navCardLink='/main/discounts' ><RiPriceTag2Fill /></NavCard>
                <NavCard cardName={'Delivery Fees'} navCardLink='/main/delivery-fees' ><RiTruckFill /></NavCard>
                <NavCard cardName={'Promo Code'} navCardLink='/main/promoCode' ><GiTicket /></NavCard>
                <NavCard cardName={'Colors'} navCardLink='/main/colors' ><RiColorFilterLine /></NavCard>

            </NavCardList>
        </ScreenHolder>
    )
}

export default EcommercesSettings
