export const WEBSITE_CONTENTS_API = "websiteContents/";
export const ECOMMERCE_SETTINGS_API = "ecommerceSettings/";
export const EMPLOYEES_API = "manageEmployees/";
export const CUSTOMERS_API = "customers/manageCustomers/";

export const HERO_SLIDERS_API = WEBSITE_CONTENTS_API + "heroSliders/";
export const SOCIAL_LINKS_API = WEBSITE_CONTENTS_API + "socialLinks/";
export const TESTIMONIALS_API = WEBSITE_CONTENTS_API + "testimonials/";
export const NEWS_AND_EVENTS_API = WEBSITE_CONTENTS_API + "newsAndEvents/";
export const BLOGS_API = WEBSITE_CONTENTS_API + "blogs/";
export const CONTENT_SLIDERS_API = WEBSITE_CONTENTS_API + "contentSliders/";
export const CONTACT_INFOS_API = WEBSITE_CONTENTS_API + "contactInfos/";

export const VAT_API = ECOMMERCE_SETTINGS_API + "vats/";
export const PROMO_CODE_API = ECOMMERCE_SETTINGS_API + "promoCodes/";
export const DISCOUNT_API = ECOMMERCE_SETTINGS_API + "discounts/";
export const DISCOUNT_FEE_API = ECOMMERCE_SETTINGS_API + "deliveryFees/";
export const COLORS_API = ECOMMERCE_SETTINGS_API + "colors/";
export const ALL_EMPLOYEE_API = EMPLOYEES_API + "getAllEmployees/";
export const ALL_CUSTOMERS_API = CUSTOMERS_API + "getAllCustomers/";

