import { useContext, useState } from "react";
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import {
  COLLECTIONS_API,
  NAV_COLLECTION_API,
} from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import ShortTextInput from "../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../../../../Partials/Forms/Form";
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useGetInfo } from "antopolis-react-utils/hooks";
import SearchSelectField from "../../../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField";
import NumberInput from "../../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";

function CreateNavCollection({ setShowCreateForm }) {
  const [collection, setCollection] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [error, setError] = useState("");

  const { triggerFetch, toggleFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  const { info: collections } = useGetInfo({
    axiosInstance: axiosInstance,
    api: COLLECTIONS_API + "getAllCollections",
    toggleFetch,
  });


  console.log(collections);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = {
      collectionID: collection,
      precedence
    };

    try {
      const response = await axiosInstance.post(
        NAV_COLLECTION_API + "createNavCollection",
        itemData
      );
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
      }
    } catch (error) {
      setError(
        "An error occurred while creating the nav collection. Please try again."
      );
    }
  }

  return (
    <div className="create_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <SearchSelectField
          label="Collections"
          optionLabel="name"
          optionValue="_id"
          value={collection}
          options={collections}
          placeholder="Enter Description"
          setState={setCollection}
        />
        <div className="input_group" style={{ minHeight: '100px' }}></div>
        <NumberInput
          label="Precedence"
          value={precedence}
          placeholder="Enter Precedence"
          setState={setPrecedence}
        />
        <FormSubmitButton text="Create Nav Collection" />
      </Form>
    </div>
  );
}

export default CreateNavCollection;
