import { useGetInfo } from "antopolis-react-utils/hooks";
import { useParams } from "react-router-dom";
import { ORDERS_API } from "../../../../../../Utilities/APIs/OrderAPIs/OrderAPIs";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { ShortTextCell } from "../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ImageCell } from "../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";


function OrderItems() {

  const axiosInstance = useAxiosInstance();
  const { orderID } = useParams();

  const { info: orderItems } = useGetInfo({
    axiosInstance: axiosInstance,
    api: ORDERS_API + 'getOrderItemsFromOrder/' + orderID,

  });


  return (
    <div className="screen_wrapper">
      <div className="screen_header" >
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">Order Items ({orderItems?.length} in total)</h1>
        </div>
      </div>
      <CRUDTable >
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Image" />
          <CRUDth th="Quantity" />
          <CRUDth th="Price" />
          <CRUDth th="Total" />


        </CRUDTableHeader>
        <tbody>
          {orderItems?.map((orderItem) => (
            <tr key={orderItem._id}>
              <td><p className="product-name">{orderItem?.item?.name}</p></td>
              {/* <ShortTextCell text={orderItem?.item?.name} /> */}
              <ImageCell imgSrc={orderItem?.item?.image} />
              <ShortTextCell text={orderItem.quantity} />
              <ShortTextCell text={orderItem?.item?.price} />
              <ShortTextCell text={orderItem.total} />

            </tr>
          ))}
        </tbody>

      </CRUDTable>


    </div>
  );
}

export default OrderItems;
