import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { NAV_COLLECTION_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";

function ViewNavCollection({ targetID }) {
  const axiosInstance = useAxiosInstance();

  const { info: collection } = useGetInfo({
    axiosInstance: axiosInstance,
    api: NAV_COLLECTION_API + "singleNavCollection/" + targetID,
  });

  return (
    <div className="crud_view_content">
      {collection && (
        <>
          <h1>Nav Collection Details</h1>
          <div>
            <strong>ID:</strong> {collection.collectionID}
          </div>

          <div>
            <strong>Precedence:</strong> {collection.precedence}
          </div>
        </>
      )}
    </div>
  );
}

export default ViewNavCollection;
