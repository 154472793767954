function FilterSelect({ filter, setFilter }) {

    return (
        
        <div style={{display:"flex",alignItems:"center"}}>

         <select className="filter_select" value={filter} onChange={(e)=>setFilter(e.target.value)} >
            <option value={"active"}>Active</option>
            <option value={"archive"}>Archived</option>
        </select>   
        </div>
        
    )
}

export default FilterSelect