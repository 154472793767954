import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { ITEM_COLLECTIONS_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";

function ViewItemCollection({ targetID }) {
  const axiosInstance = useAxiosInstance();

  const { info: itemCollection } = useGetInfo({
    axiosInstance: axiosInstance,
    api: ITEM_COLLECTIONS_API + "singleItemCollection/" + targetID,
  });

  console.log(itemCollection);

  return (
    <div className="crud_view_content">
      {itemCollection && (
        <>
          <h1>Item:</h1>
          <p>{itemCollection.item.name}</p>

          {/* <h1>Collection:</h1>
          <p> {itemCollection.collectionID}</p> */}
        </>
      )}
    </div>
  );
}

export default ViewItemCollection;
