import { useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { CreateButton } from "../../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { EditButton, ViewButton } from "../../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../../../Partials/Elements/Modal/Modal";
import { useUseOrientation } from "../../../../../../../Utilities/Hooks/useUseOrientation";
import CreateRecommendedItem from "./CreateRecommendedItem";
import { ArchiveButton } from "../../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import FilterSelect from "../../../../../../Partials/Forms/FilterSelect/FilterSelect";
import ArchiveItem from "../../../../../../Partials/Layout/ArchiveItem/ArchiveItem";
import { ImageCell } from "../../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { useParams } from "react-router-dom";


function RecommendedItems({ api }) {
    const [showModal, setShowModal] = useState(false);
    const [showArchiveSection, setShowArchiveSection] = useState(false);
    const [showActionButton, setShowActionButton] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [filter, setFilter] = useState("active");
    const [isActive, setIsActive] = useState(null);
    const [toggleFetch, setToggleFetch] = useState(false);
    const { itemId } = useParams();
    const [targetID, setTargetID] = useState(null);

    const { portrait } = useUseOrientation()
    const axiosInstance = useAxiosInstance();

    const { info: recommendedItems } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + "singleRecommendedItemsByItem/" + itemId + `?filter=${filter}`,
        toggleFetch
    });

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <div className="screen_wrapper">
            <div className="screen_header" >
                <h1 className="screen_heading">
                Recommended Items ({recommendedItems?.length} in total)
                </h1>
                <FilterSelect filter={filter} setFilter={setFilter} />
                <CreateButton
                    screenTopicSingular={"Recommended Items"}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable >
                <CRUDTableHeader>
                    <CRUDth th="Image" />
                    <CRUDth th="Items" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {recommendedItems?.map((recommendedItem) => (
                        <tr key={recommendedItem._id}>
                            <ImageCell imgSrc={recommendedItem?.recommendedItem?.image} imgAlt={recommendedItem?.recommendedItem?.name} />
                            <td>{recommendedItem?.recommendedItem?.name}</td>
                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === recommendedItem._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(recommendedItem._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            <ArchiveButton
                                                setShowArchiveForm={setShowArchiveSection}
                                                targetID={recommendedItem._id}
                                                setTargetID={setTargetID}
                                                isActive={recommendedItem?.isActive}
                                                setActive={setIsActive} />

                                        </div>
                                    )}
                                    {portrait && showActionButton && targetID === recommendedItem._id && (
                                        <div className="action_buttons">
                                            <ArchiveButton
                                                setShowArchiveForm={setShowArchiveSection}
                                                targetID={recommendedItem._id}
                                                setTargetID={setTargetID}
                                                isActive={recommendedItem?.isActive}
                                                setActive={setIsActive} />
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Related Items`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateRecommendedItem
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}
                        api={api}
                        itemId={itemId}
                    />

                </Modal>
            )}

            {showArchiveSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Archive Related Items`}
                    setShowModalContent={setShowArchiveSection}
                >
                    <ArchiveItem
                        api={`${api}archiveRecommendedItem/`}
                        isActive={isActive}
                        targetID={targetID}
                        setShowModal={setShowModal}
                        setShowActionButton={setShowArchiveSection}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}


        </div>
    );
}

export default RecommendedItems;
