import {useEffect, useState } from "react"
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Forms/Form"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"

function UpdateContactInfo({ api, setShowUpdateForm, triggerFetch, targetID }) {
   
     const [email, setEmail] = useState('')
     const [phone, setPhone] = useState('')
     const [location, setLocation] = useState('')
    const axiosInstance = useAxiosInstance()

    const { info: contactInfo } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + 'getSingleContactInfo/' + targetID,
    })
 useEffect(() => {
    if (contactInfo) {
        setEmail(contactInfo.email)
        setPhone(contactInfo.phone)
        setLocation(contactInfo.location)
    }
}, [contactInfo])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = {
            email,
            phone,
            location
        }

        const response = await axiosInstance.patch(api + 'getSingleContactInfo/' + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <ShortTextInput
                    label={`Email`}
                    value={email}
                    placeholder={`Enter Email`}
                    setState={setEmail}
                />
                <ShortTextInput
                    label={`Phone`}
                    value={phone}
                    placeholder={`Enter Phone`}
                    setState={setPhone}
                />
                <ShortTextInput
                    label={`Location`}
                    value={location}
                    placeholder={`Enter Location`}
                    setState={setLocation}
                />


                <FormSubmitButton text="Update Contact Info" />[]
            </Form>
        </div>
    )
}

export default UpdateContactInfo