export const ITEMS_API = "items/";
export const ITEMS_CLASSIFIED_API = "classifiedItems/";

// Control Items
export const CONTROL_ITEMS_API = ITEMS_API + "controlItems/";

// item groups
export const ITEMS_GROUP_API = ITEMS_API + "itemGroups/";
export const CATEGORIES_API = ITEMS_GROUP_API + "categories/";
export const SUBCATEGORIES = ITEMS_GROUP_API + "subCategories/";
export const MANAGE_CATEGORIES_API = CATEGORIES_API + "manageCategories/";

export const COLLECTIONS_API = ITEMS_GROUP_API + "collections/";
export const NAV_COLLECTION_API = ITEMS_GROUP_API + "navCollections/";
export const ITEM_COLLECTIONS_API = ITEMS_GROUP_API + "itemCollections/";

// manage items
export const MANAGE_ITEMS_API = CONTROL_ITEMS_API + "manageItem/";


export const ITEM_ASSETS_API = CONTROL_ITEMS_API + "itemAssets/";
export const FEATURED_ITEM_API = ITEMS_API + ITEMS_CLASSIFIED_API + "featuredItems/";
export const ITEM_LARGE_IMAGES_API = ITEMS_API + ITEMS_CLASSIFIED_API + "itemLargeImages/";
export const ITEM_BENEFITS_API = ITEMS_API + ITEMS_CLASSIFIED_API + "itemBenefits/";
export const ITEM_COLORS_API = ITEMS_API + ITEMS_CLASSIFIED_API + "itemColors/";
export const RELATED_ITEM_API = ITEMS_API + ITEMS_CLASSIFIED_API + "relatedItems/";
export const RECOMMENDED_ITEM_API = ITEMS_API + ITEMS_CLASSIFIED_API + "recommendedItems/";
export const PROMOTIONAL_API = ITEMS_API + "promotional/";
