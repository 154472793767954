import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { ImageCell } from "../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";

function ViewPromotional({ targetID, api }) {
  const axiosInstance = useAxiosInstance();

  const { info: item } = useGetInfo({
    axiosInstance: axiosInstance,
    api: `${api}${targetID}`,
  });

  return (
    <div className="crud_view_content">
      {item && (
        <>
          <h1>Image</h1>
          <ImageCell
            imgSrc={item.image}
            imgAlt={item.name}
            className="item-image"
          />
          <h1>Product Url</h1>
          <h1>{item.productUrl}</h1>
        </>
      )}
    </div>
  );
}

export default ViewPromotional;
