import React, { useEffect, useState } from 'react'
import { IconThreeDots } from "antopolis-react-icons";
import { CreateButton } from '../../../../Partials/Layout/CRUD/CreateButton/CreateButton'
import FilterSelect from '../../../../Partials/Forms/FilterSelect/FilterSelect'
import BackButton from '../../../../Partials/Elements/BackButton/BackButton'
import { useAxiosInstance } from '../../../../../Utilities/Hooks/useAxiosInstance'
import { CRUDTable } from '../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable'
import { CRUDTableHeader } from '../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader'
import { CRUDth } from '../../../../Partials/Layout/CRUD/CRUDth/CRUDth'
import { ArchiveButton, CRUDButton, EditButton, ViewButton } from '../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons'
import { useUseOrientation } from '../../../../../Utilities/Hooks/useUseOrientation';
import { useGetInfo } from 'antopolis-react-utils/hooks';
import { Modal } from '../../../../Partials/Elements/Modal/Modal';
import ArchiveItem from '../../../../Partials/Layout/ArchiveItem/ArchiveItem';
import { DEALER_DIVISIONS_API } from '../../../../../Utilities/APIs/DealerDivisionAPIs/DealerDivisionAPIs';
import { ShortTextCell } from '../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell';
import CreateDealerDivision from './CreateDealerDivision';
import UpdateDealerDivision from './UpdateDealerDivision';
import { RiImage2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';


function DealerDivisions() {

    const [showModal, setShowModal] = useState(false);
    const [showActionButton, setShowActionButton] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showArchiveSection, setShowArchiveSection] = useState(false);

    const [filter, setFilter] = useState("active");
    const [toggleFetch, setToggleFetch] = useState(false);
    const navigate = useNavigate();

    const axiosInstance = useAxiosInstance();

    const [targetID, setTargetID] = useState(null);
    const { portrait } = useUseOrientation()

    const [isActive, setIsActive] = useState(null)

    const { info: dealerDivisions } = useGetInfo({
        axiosInstance: axiosInstance,
        api: DEALER_DIVISIONS_API + "getAllDealerDivisions" + `?filter=${filter}`,
        toggleFetch
    });

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <div className="screen_wrapper">
            <div className="screen_header" >
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton />
                    <h1 className="screen_heading">{`Dealer Divisions (${dealerDivisions?.length} in total)`}</h1>
                </div>
                <FilterSelect filter={filter} setFilter={setFilter} />
                <CreateButton
                    screenTopicSingular={"Dealer Division"}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable >
                <CRUDTableHeader>
                    <CRUDth th="Name" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {dealerDivisions?.map((dealerDivision) => (
                        <tr key={dealerDivision._id}>
                            <ShortTextCell text={dealerDivision.name} />
                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === dealerDivision._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(dealerDivision._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            {/* <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={vat._id}
                                                setTargetID={setTargetID}
                                            /> */}
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={dealerDivision._id}
                                                setTargetID={setTargetID}
                                            />
                                            <ArchiveButton
                                                setShowArchiveForm={setShowArchiveSection}
                                                targetID={dealerDivision._id}
                                                setTargetID={setTargetID}
                                                isActive={dealerDivision?.isActive}
                                                setActive={setIsActive} />

                                            <CRUDButton
                                                handleClick={() => {
                                                    navigate(`/main/dealerAreas/${dealerDivision._id}`);
                                                }}
                                            >
                                                <RiImage2Line />
                                            </CRUDButton>
                                        </div>

                                    )}

                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Dealer Division`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateDealerDivision
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}

                    />
                </Modal>
            )}
            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Dealer Division`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateDealerDivision
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                        api={DEALER_DIVISIONS_API}
                    />

                </Modal>
            )}
            {showArchiveSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Archive Dealer Division`}
                    setShowModalContent={setShowArchiveSection}
                >
                    <ArchiveItem
                        api={`${DEALER_DIVISIONS_API}archiveDealerDivision/`}
                        isActive={isActive}
                        targetID={targetID}
                        setShowModal={setShowModal}
                        setShowActionButton={setShowArchiveSection}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}

        </div>

    )
}

export default DealerDivisions
