import ClassicEditor from 'ckeditor5-custom-build'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import "./CustomEditor.css"



function CustomEditor({ label, setState, data }) {
    return (
        <div className="input_group">
            <label className="input_field_label caption bold">
                <strong>{label}</strong>
            </label>
            <div className="custom_editor">
                <CKEditor
                    editor={ClassicEditor}
                    data={data}
                    onChange={(event, editor) => {
                        setState(editor.getData())

                    }}
                />
            </div>
        </div>
    )
}

export default CustomEditor