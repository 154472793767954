import { useContext, useEffect, useState } from "react"
import { useGetInfo } from "antopolis-react-utils/hooks"
import Form from "../../../../../../Partials/Forms/Form"
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import SearchSelectField from "../../../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField"
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext"
import { CATEGORIES_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs"
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance"

function CreateRelatedItem({ api, setShowCreateForm, triggerFetch, itemId }) {
    const { toggleFetch } = useContext(LayoutContext)
    const [allItems, setAllItems] = useState([])
    const [category, setCategory] = useState("all")
    const [item, setItem] = useState(null)
    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = {
            item: itemId,
            relatedItem: item
        }

        const response = await axiosInstance.post(api + "/createRelatedItem", itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
            setAllItems([])
            setCategory("all")
            setItem(null)
        }
    }

    const { info: categories } = useGetInfo({
        axiosInstance: axiosInstance,
        api: CATEGORIES_API + "getAllCategories",
        toggleFetch,
    });

    useEffect(() => {
        const hendleGetItemsByCategory = async () => {

            const response = await axiosInstance.get(`adminOrder/getItemsByCategory?category=${category}`)

            if (response) {
                setAllItems(response?.data)

            }


        }
        hendleGetItemsByCategory()

    }, [category])
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <SearchSelectField
                    label="Categories"
                    optionLabel="name"
                    optionValue="slug"
                    value={category}
                    options={categories}
                    placeholder="Enter Category"
                    setState={setCategory}
                />
                <SearchSelectField
                    label="Products"
                    optionLabel="name"
                    optionValue="_id"
                    value={item}
                    options={allItems}
                    placeholder="Enter Product"
                    setState={setItem}
                />
                <FormSubmitButton text="Create Related Items" />
            </Form>
        </div>
    )
}

export default CreateRelatedItem