import { useContext, useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";

import { useUseOrientation } from "../../../../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import {
  ITEM_COLLECTIONS_API,
  NAV_COLLECTION_API,
} from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { CreateButton } from "../../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import {
  EditButton,
  ViewButton,
} from "../../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../../../Partials/Elements/Modal/Modal";
import CreateItemCollection from "./CreateItemCollection";
import UpdateItemCollection from "./UpdateItemCollection";
import ViewItemCollection from "./ViewItemCollection";
import { useParams } from "react-router-dom";

function ItemCollection() {
  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);

  const [targetID, setTargetID] = useState(null);

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();
  const { toggleFetch } = useContext(LayoutContext);
  const { collectionID } = useParams();

  const { info: itemCollections } = useGetInfo({
    axiosInstance: axiosInstance,
    api: ITEM_COLLECTIONS_API + "getAllItemsFromOneCollection/" + collectionID,
    toggleFetch,
  });

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">
          Item Collections ({itemCollections?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Item Collection"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Item" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {itemCollections?.map((itemCollection) => (
            <tr key={itemCollection._id}>
              <td>{itemCollection?.item?.name}</td>

              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      if (targetID === itemCollection._id) {
                        setTargetID("");
                      } else {
                        setShowActionButton(true);
                        setTargetID(itemCollection._id);
                      }
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={itemCollection._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={itemCollection._id}
                        setTargetID={setTargetID}
                      />
                    </div>
                  )}
                  {portrait &&
                    showActionButton &&
                    targetID === itemCollection._id && (
                      <div className="action_buttons">
                        <ViewButton
                          setShowViewSection={setShowViewSection}
                          targetID={itemCollection._id}
                          setTargetID={setTargetID}
                        />
                        <EditButton
                          setShowUpdateForm={setShowUpdateForm}
                          targetID={itemCollection._id}
                          setTargetID={setTargetID}
                          setShowActionButton={setShowActionButton}
                        />
                      </div>
                    )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>

        {/* <Pagination
          totalPages={totalPages}
          totalItems={totalClients}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        /> */}
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Item Collection`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateItemCollection
            setShowCreateForm={setShowCreateForm}
            collectionID={collectionID}
          />
        </Modal>
      )}

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Collection`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateItemCollection
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            collectionID={collectionID}
          />
        </Modal>
      )}
      {/* 

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Client`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateClient
            employee={employee}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Client`}
          setShowModalContent={setShowViewSection}
        >
          <ViewClient triggerFetch={triggerFetch} targetID={targetID} />
        </Modal>
      )} */}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Item Collection`}
          setShowModalContent={setShowViewSection}
        >
          <ViewItemCollection targetID={targetID} />
        </Modal>
      )}
    </div>
  );
}

export default ItemCollection;
