import { useContext, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"

function CreateHeroSlider({ api, setShowCreateForm, triggerFetch }) {

    const [title, setTitle] = useState('')
    const [image, setImage] = useState('')
    // const [precedence, setPrecedence] = useState('')
    const [mobileImage, setMobileImage] = useState('')

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('title', title)
        itemData.append('image', image)
        // itemData.append('precedence', precedence)
        itemData.append('mobileImage', mobileImage)

        const response = await axiosInstance.post(api + "createHeroSlider", itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <ShortTextInput
                    label={`Title`}
                    value={title}
                    placeholder={`Enter Title`}
                    setState={setTitle}

                />

                <ImageInput
                    fieldId='1'
                    state={image}
                    setState={setImage}
                    allowCreateImage
                >
                    Upload Image (1950 x 1000)
                </ImageInput>

                <ImageInput
                    fieldId='2'
                    state={mobileImage}
                    setState={setMobileImage}
                    allowCreateImage
                >
                    Upload Mobile Image (375 x 510)
                </ImageInput>

                {/* <NumberInput
                    label={`Precedence`}
                    value={precedence}
                    placeholder={`Enter Precedence`}
                    setState={setPrecedence}

                /> */}


                <FormSubmitButton text="create Hero Slider" />
            </Form>
        </div>
    )
}

export default CreateHeroSlider