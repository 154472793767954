import { useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { CreateButton } from "../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { EditButton, ViewButton } from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";
import { useUseOrientation } from "../../../../../Utilities/Hooks/useUseOrientation";
import CreateContactInfo from "./CreateContactInfo";
import ViewContactInfo from "./ViewContactInfo";
import UpdateContactInfo from "./UpdateContactInfo";


function ContactInfos({ api }) {

    const [showActionButton, setShowActionButton] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showViewSection, setShowViewSection] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);

    const [targetID, setTargetID] = useState(null);

    const { portrait } = useUseOrientation()
    const axiosInstance = useAxiosInstance();

    const { info: contactInfos } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api,
        toggleFetch
    });

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <div className="screen_wrapper">
            <div className="screen_header" >
                <h1 className="screen_heading">
                    Contact Infos ({contactInfos?.length} in total)
                </h1>
                {contactInfos?.length < 1 && <CreateButton
                    screenTopicSingular={"Contact Info"}
                    setShowCreateForm={setShowCreateForm}
                />}
            </div>
            <CRUDTable >
                <CRUDTableHeader>
                    <CRUDth th="Email" />
                    <CRUDth th="Phone" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {contactInfos?.map((contactInfo) => (
                        <tr key={contactInfo._id}>
                            <td>{contactInfo?.email}</td>
                            <td>{contactInfo?.phone}</td>
                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === contactInfo._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(contactInfo._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={contactInfo._id}
                                                setTargetID={setTargetID}
                                            />
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={contactInfo._id}
                                                setTargetID={setTargetID}
                                            />
                                        </div>
                                    )}
                                    {portrait && showActionButton && targetID === contactInfo._id && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={contactInfo._id}
                                                setTargetID={setTargetID}
                                            />
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={contactInfo._id}
                                                setTargetID={setTargetID}
                                                setShowActionButton={setShowActionButton}
                                            />
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Contact Info`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateContactInfo
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}
                        api={api}
                    />

                </Modal>
            )}
            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Contact Info`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateContactInfo
                        setShowUpdateForm={setShowUpdateForm}   
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                        api={api}
                    />
                </Modal>
            )}

            {showViewSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`View Contact Info`}
                    setShowModalContent={setShowViewSection}
                >
                    <ViewContactInfo
                        targetID={targetID}
                        api={api}
                    />
                </Modal>
            )}

        </div>
    );
}

export default ContactInfos;
