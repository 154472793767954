import { useEffect, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import { useGetInfo } from "antopolis-react-utils/hooks"
import Form from "../../../../Partials/Forms/Form"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput"
import CustomEditor from "../../../../Partials/Elements/CustomEditor/CustomEditor"


function UpdateBlog({ api, setShowUpdateForm, targetID, triggerFetch }) {

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')

    const axiosInstance = useAxiosInstance()

    const { info: newsAndEvents } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + 'singleBlog/' + targetID,
    })
    useEffect(() => {
        if (newsAndEvents) {
            setTitle(newsAndEvents.title)
            setDescription(newsAndEvents.description)
            setImage(newsAndEvents.image)
        }
    }, [newsAndEvents])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('title', title)
        itemData.append('description', description)
        itemData.append('image', image)

        const response = await axiosInstance.patch(api + 'singleBlog/' + targetID, itemData)

        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }


    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <ShortTextInput
                    label={`Title`}
                    value={title}
                    placeholder={`Enter Title`}
                    setState={setTitle}

                />

                <CustomEditor label="Description" setState={setDescription} data={description || ""}  />

                <ImageInput
                    fieldId='1'
                    state={image}
                    setState={setImage}
                    allowUpdateImage
                >
                    Upload Image (550 x 450)
                </ImageInput>

                <FormSubmitButton text="Update Blog" />
            </Form>
        </div>
    )
}

export default UpdateBlog