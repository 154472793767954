import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";


import { SUBCATEGORIES } from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { useUseOrientation } from "../../../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import { CreateButton } from "../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import {
    ArchiveButton,
    EditButton,
    ViewButton,
} from "../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import CreateSubcategory from "./CreateSubcategory";
import ViewSubcategory from "./ViewSubcategory";
import UpdateSubcategory from "./UpdateSubcategory";
import FilterSelect from "../../../../../Partials/Forms/FilterSelect/FilterSelect";
import ArchiveItem from "../../../../../Partials/Layout/ArchiveItem/ArchiveItem";

function Subcategories() {
    const [showModal, setShowModal] = useState(false);
    const [showActionButton, setShowActionButton] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showViewSection, setShowViewSection] = useState(false);
    const [showArchiveSection, setShowArchiveSection] = useState(false);
    const [filter, setFilter] = useState("active");
    const [isActive, setIsActive] = useState(null)

    const [targetID, setTargetID] = useState(null);
    const { categoryID } = useParams();

    const { portrait } = useUseOrientation();
    const axiosInstance = useAxiosInstance();
    const { toggleFetch,triggerFetch } = useContext(LayoutContext);

    const { info: subCategories } = useGetInfo({
        axiosInstance: axiosInstance,
        api: SUBCATEGORIES + "singleSubCategoriesByCategory/" + categoryID + `?filter=${filter}`,
        toggleFetch,
    });

    return (
        <div className="screen_wrapper">
            <div className="screen_header">
                <h1 className="screen_heading">
                    SubCategories ({subCategories?.length} in total)
                </h1>
                <FilterSelect filter={filter} setFilter={setFilter} />
                <CreateButton
                    screenTopicSingular={"SubCategory"}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth th="Name" />
                    <CRUDth th="Slug" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {subCategories?.map((subCategory) => (
                        <tr key={subCategory._id}>
                            <td>{subCategory.name}</td>
                            <td>{subCategory.slug}</td>
                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === subCategory._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(subCategory._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={subCategory._id}
                                                setTargetID={setTargetID}
                                            />
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={subCategory._id}
                                                setTargetID={setTargetID}
                                            />
                                            <ArchiveButton
                                                setShowArchiveForm={setShowArchiveSection}
                                                targetID={subCategory._id}
                                                setTargetID={setTargetID}
                                                isActive={subCategory?.isActive}
                                                setActive={setIsActive} />
                                        </div>
                                    )}
                                    {portrait &&
                                        showActionButton &&
                                        targetID === subCategory._id && (
                                            <div className="action_buttons">
                                                <ViewButton
                                                    setShowViewSection={setShowViewSection}
                                                    targetID={subCategory._id}
                                                    setTargetID={setTargetID}
                                                />
                                                <EditButton
                                                    setShowUpdateForm={setShowUpdateForm}
                                                    targetID={subCategory._id}
                                                    setTargetID={setTargetID}
                                                    setShowActionButton={setShowActionButton}
                                                />
                                                <ArchiveButton
                                                    setShowArchiveForm={setShowArchiveSection}
                                                    targetID={subCategory._id}
                                                    setTargetID={setTargetID}
                                                    isActive={subCategory?.isActive}
                                                    setActive={setIsActive} />
                                            </div>
                                        )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

                {/* <Pagination
          totalPages={totalPages}
          totalItems={totalClients}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        /> */}
            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create SubCategory`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateSubcategory
                        setShowCreateForm={setShowCreateForm}
                        categoryID={categoryID}
                    />
                </Modal>
            )}
            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update SubCategory`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateSubcategory
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={targetID}
                        categoryID={categoryID}
                    />
                </Modal>
            )}
            {/* 

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Client`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateClient
            employee={employee}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Client`}
          setShowModalContent={setShowViewSection}
        >
          <ViewClient triggerFetch={triggerFetch} targetID={targetID} />
        </Modal>
      )} */}

            {showViewSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`View SubCategory`}
                    setShowModalContent={setShowViewSection}
                >
                    <ViewSubcategory targetID={targetID} />
                </Modal>
            )}
            {showArchiveSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Archive SubCategory`}
                    setShowModalContent={setShowArchiveSection}
                >
                    <ArchiveItem
                        api={`${SUBCATEGORIES}archiveSubCategory/`}
                        isActive={isActive}
                        targetID={targetID}
                        setShowModal={setShowModal}
                        setShowActionButton={setShowArchiveSection}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}

        </div>
    );
}

export default Subcategories;
