import { useContext, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"

function CreateColors({ api, setShowCreateForm, triggerFetch }) {

    const [name, setName] = useState('')

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('name', name)

        const response = await axiosInstance.post(api + 'createColor', itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <ShortTextInput
                    label={`Name`}
                    value={name}
                    placeholder={`Enter Name`}
                    setState={setName}

                />

                <FormSubmitButton text="Create Color" />
            </Form>
        </div >
    )
}

export default CreateColors