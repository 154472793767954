import { useContext, useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useUseOrientation } from "../../../../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import { ITEM_ASSETS_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { CreateButton } from "../../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import {
  ArchiveButton,
  EditButton,
  ViewButton,
} from "../../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { useParams } from "react-router-dom";
import { ImageCell } from "../../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { Modal } from "../../../../../../Partials/Elements/Modal/Modal";
import CreateItemLargeImage from "./CreateItemLargeImage";
import UpdateItemLargeImage from "./UpdateItemLargeImage";
import ViewItemLargeImage from "./ViewItemLargeImage";
import FilterSelect from "../../../../../../Partials/Forms/FilterSelect/FilterSelect";
import ArchiveItem from "../../../../../../Partials/Layout/ArchiveItem/ArchiveItem";

function ItemLargeImages({ api }) {
  const [showModal, setShowModal] = useState(false);
  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showArchiveSection, setShowArchiveSection] = useState(false);
  const [filter, setFilter] = useState("active");
  const [isActive, setIsActive] = useState(null);

  const { itemId } = useParams();

  const [targetID, setTargetID] = useState(null);

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();
  const { toggleFetch, triggerFetch } = useContext(LayoutContext);

  const { info: itemAssets } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "getLargeImagesFromItem/" + itemId + `?filter=${filter}`,
    toggleFetch,
  });

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">
          Item Large Images ({itemAssets?.length} in total)
        </h1>
        <FilterSelect filter={filter} setFilter={setFilter} />
        <CreateButton
          screenTopicSingular={" Large Images"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Image" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {itemAssets?.map((itemAsset) => (
            <tr key={itemAsset._id}>
              <td>
                <ImageCell imgSrc={itemAsset?.image} imgAlt={"Item Asset"} />
              </td>



              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      if (targetID === itemAsset._id) {
                        setTargetID("");
                      } else {
                        setShowActionButton(true);
                        setTargetID(itemAsset._id);
                      }
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={itemAsset._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={itemAsset._id}
                        setTargetID={setTargetID}
                      />
                      <ArchiveButton
                        setShowArchiveForm={setShowArchiveSection}
                        targetID={itemAsset._id}
                        setTargetID={setTargetID}
                        isActive={itemAsset?.isActive}
                        setActive={setIsActive} />
                    </div>
                  )}
                  {portrait &&
                    showActionButton &&
                    targetID === itemAsset._id && (
                      <div className="action_buttons">
                        <ViewButton
                          setShowViewSection={setShowViewSection}
                          targetID={itemAsset._id}
                          setTargetID={setTargetID}
                        />
                        <EditButton
                          setShowUpdateForm={setShowUpdateForm}
                          targetID={itemAsset._id}
                          setTargetID={setTargetID}
                          setShowActionButton={setShowActionButton}
                        />
                        <ArchiveButton
                          setShowArchiveForm={setShowArchiveSection}
                          targetID={itemAsset._id}
                          setTargetID={setTargetID}
                          isActive={itemAsset?.isActive}
                          setActive={setIsActive} />
                      </div>
                    )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>


      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Large Images`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateItemLargeImage
            api={api}
            itemId={itemId}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Large Images`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateItemLargeImage
            api={api}
            assetId={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Large Images`}
          setShowModalContent={setShowViewSection}
        >
          <ViewItemLargeImage assetId={targetID} api={api} />
        </Modal>
      )}
      {showArchiveSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Archive Item Image`}
          setShowModalContent={setShowArchiveSection}
        >
          <ArchiveItem
            api={`${api}archiveItemLargeImage/`}
            isActive={isActive}
            targetID={targetID}
            setShowModal={setShowModal}
            setShowActionButton={setShowArchiveSection}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </div>
  );
}

export default ItemLargeImages;
