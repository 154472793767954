import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { CATEGORIES_API } from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { ImageCell } from "../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";

function ViewCategory({ targetID }) {
  const axiosInstance = useAxiosInstance();

  const { info: category } = useGetInfo({
    axiosInstance: axiosInstance,
    api: CATEGORIES_API + "singleCategory/" + targetID,
  });
console.log("category",category)
  return (
    <div className="crud_view_content">
    {category && (
      <>
        <h1>Description</h1>
        <p>{category.description}</p>

        {/* <h1>Category</h1>
        <p>{item.category}</p> */}

        <h1>Precedence</h1>
        <p>{category.precedence}</p>

        {/* <h1>Image</h1>
        <ImageCell
          imgSrc={category.image}
          imgAlt={category.name}
          className="item-image"
        /> */}
      </>
    )}
  </div>
  );
}

export default ViewCategory;
