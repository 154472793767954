import { useState, useContext } from "react";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../../Partials/Forms/Form";
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import { useGetInfo } from "antopolis-react-utils/hooks";
import SearchSelectField from "../../../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField";
import { COLORS_API } from "../../../../../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs";

function CreateItemColors({ itemId, setShowCreateForm, api }) {
  const [selectedColor, setSelectedColor] = useState("");
  const [error, setError] = useState("");

  const { triggerFetch, toggleFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const assetData = new FormData();
    assetData.append("item", itemId);
    assetData.append("color", selectedColor);

    try {
      const response = await axiosInstance.post(
        api + 'createItemColor',
        assetData
      );
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
      }
    } catch (error) {
      setError("An error occurred while creating the asset. Please try again.");
    }
  }
  const { info: allColors } = useGetInfo({
    axiosInstance: axiosInstance,
    api: COLORS_API + "getAllColors?filter=active",
    toggleFetch,
  });
  return (
    <div className="create_asset_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <SearchSelectField
          label="Colors"
          optionLabel="name"
          optionValue="_id"
          value={selectedColor}
          options={allColors}
          placeholder="Enter color"
          setState={setSelectedColor}
        />

        <FormSubmitButton text="Create Item Benefit" />
      </Form>
    </div>
  );
}

export default CreateItemColors;
