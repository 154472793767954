import React, { useEffect, useState } from 'react'
import { IconThreeDots } from "antopolis-react-icons";
import { CreateButton } from '../../../../Partials/Layout/CRUD/CreateButton/CreateButton'
import FilterSelect from '../../../../Partials/Forms/FilterSelect/FilterSelect'
import BackButton from '../../../../Partials/Elements/BackButton/BackButton'
import { useAxiosInstance } from '../../../../../Utilities/Hooks/useAxiosInstance'
import { CRUDTable } from '../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable'
import { CRUDTableHeader } from '../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader'
import { CRUDth } from '../../../../Partials/Layout/CRUD/CRUDth/CRUDth'
import { ArchiveButton, CRUDButton, EditButton, ViewButton } from '../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons'
import { useUseOrientation } from '../../../../../Utilities/Hooks/useUseOrientation';
import { useGetInfo } from 'antopolis-react-utils/hooks';
import { Modal } from '../../../../Partials/Elements/Modal/Modal';
import ArchiveItem from '../../../../Partials/Layout/ArchiveItem/ArchiveItem';
import { DEALER_AREA_API } from '../../../../../Utilities/APIs/DealerDivisionAPIs/DealerDivisionAPIs';
import { ShortTextCell } from '../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell';
import { useNavigate, useParams } from 'react-router-dom';
import CreateDealerArea from './CreateDealerArea';
import UpdateDealerArea from './UpdateDealerArea';
import { RiImage2Line } from 'react-icons/ri';


function DealerAreas() {

    const [showModal, setShowModal] = useState(false);
    const [showActionButton, setShowActionButton] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showArchiveSection, setShowArchiveSection] = useState(false);

    const [filter, setFilter] = useState("active");
    const [toggleFetch, setToggleFetch] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const axiosInstance = useAxiosInstance();

    const [targetID, setTargetID] = useState(null);
    const { portrait } = useUseOrientation()

    const [isActive, setIsActive] = useState(null)

    const { info: dealerAreas } = useGetInfo({
        axiosInstance: axiosInstance,
        api: DEALER_AREA_API + "getAllDealerAreasByDealerDivision/" + id + `?filter=${filter}`,
        toggleFetch
    });

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <div className="screen_wrapper">
            <div className="screen_header" >
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton />
                    <h1 className="screen_heading">{`Dealer Areas (${dealerAreas?.length} in total)`}</h1>
                </div>
                <FilterSelect filter={filter} setFilter={setFilter} />
                <CreateButton
                    screenTopicSingular={"Dealer Area"}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable >
                <CRUDTableHeader>
                    <CRUDth th="Name" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {dealerAreas?.map((dealerArea) => (
                        <tr key={dealerArea._id}>
                            <ShortTextCell text={dealerArea.name} />
                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === dealerArea._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(dealerArea._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            {/* <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={vat._id}
                                                setTargetID={setTargetID}
                                            /> */}
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={dealerArea._id}
                                                setTargetID={setTargetID}
                                            />
                                            <ArchiveButton
                                                setShowArchiveForm={setShowArchiveSection}
                                                targetID={dealerArea._id}
                                                setTargetID={setTargetID}
                                                isActive={dealerArea?.isActive}
                                                setActive={setIsActive}
                                            />

                                            <CRUDButton
                                                handleClick={() => {
                                                    navigate(`/main/dealers/${dealerArea._id}`);
                                                }}
                                            >
                                                <RiImage2Line />
                                            </CRUDButton>
                                        </div>

                                    )}

                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Dealer Area`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateDealerArea
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}
                        dealerDivisionID={id}

                    />
                </Modal>
            )}
            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Dealer Area`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateDealerArea
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                        api={DEALER_AREA_API}
                    />

                </Modal>
            )}
            {showArchiveSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Archive Dealer Area`}
                    setShowModalContent={setShowArchiveSection}
                >
                    <ArchiveItem
                        api={`${DEALER_AREA_API}archiveDealerArea/`}
                        isActive={isActive}
                        targetID={targetID}
                        setShowModal={setShowModal}
                        setShowActionButton={setShowArchiveSection}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}

        </div>

    )
}

export default DealerAreas
