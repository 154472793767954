import { useEffect, useState, useContext } from "react";
import slugify from "react-slugify";
import { useGetInfo } from "antopolis-react-utils/hooks";
import {
  CATEGORIES_API,
  MANAGE_ITEMS_API,
  SUBCATEGORIES,
} from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import Form from "../../../../../Partials/Forms/Form";
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import SearchSelectField from "../../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField";
import NumberInput from "../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import CustomEditor from "../../../../../Partials/Elements/CustomEditor/CustomEditor";
import SwitchInput from "../../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";
import SelectInput from "../../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";

function UpdatePromotional({ targetID, setShowUpdateForm, api }) {

  const [image, setImage] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [error, setError] = useState("");

  const { triggerFetch, toggleFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchItemDetails() {
      try {
        const response = await axiosInstance.get(`${api}${targetID}`);

        const item = response.data;
        setImage(item.image);
        setProductUrl(item.productUrl);
      } catch (error) {
        setError("An error occurred while fetching the item details. Please try again.");
      }
    }

    fetchItemDetails();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("image", image);
    itemData.append("productUrl", productUrl);

    try {
      const response = await axiosInstance.patch(
        `${api}/${targetID}`,
        itemData
      );
      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
      }
    } catch (error) {
      setError("An error occurred while updating the item. Please try again.");
    }
  }
  console.log("image",image)
  return (
    <div className="update_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ImageInput
          fieldId="2"
          state={image}
          setState={setImage}
          allowUpdateImage
        >
          Upload Promotional Image (550 x 650)
        </ImageInput>
        <ShortTextInput
          label="Product Url"
          value={productUrl}
          placeholder="Enter Product URL"
          setState={setProductUrl}
        />
        <FormSubmitButton text="Update Item" />
      </Form>
    </div>
  );
}

export default UpdatePromotional;
