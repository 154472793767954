import { useState, useEffect } from "react";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../../Partials/Forms/Form";
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { ITEM_ASSETS_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { useGetInfo } from "antopolis-react-utils/hooks";
import ShortTextInput from "../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";

function UpdateItemBenefits({ targetID, api, setShowUpdateForm }) {
  const axiosInstance = useAxiosInstance();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  const { info: itemBenefit } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + 'singleItemBenefit/' + targetID,
  })

  useEffect(() => {
    if (itemBenefit) {
      setTitle(itemBenefit.title)
      setDescription(itemBenefit.description)
    }
  }, [itemBenefit])

  async function handleSubmit(e) {
    e.preventDefault();

    const assetData = new FormData();
    assetData.append("title", title);
    assetData.append("description", description);

    try {
      const response = await axiosInstance.patch(
        ITEM_ASSETS_API + "singleItemAsset/" + targetID,
        assetData
      );
      if (response) {
        setShowUpdateForm(false);
      }
    } catch (error) {
      setError("An error occurred while updating the asset. Please try again.");
    }
  }



  return (
    <div className="update_form">

      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label="Title"
          value={title}
          placeholder="Enter Title"
          setState={setTitle}
        />
        <ShortTextInput
          label="Description"
          value={description}
          placeholder="Enter Description"
          setState={setDescription}
        />

        <FormSubmitButton text="Update Item Benefit" />
      </Form>
    </div>
  );
}

export default UpdateItemBenefits;
