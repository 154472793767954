import { useContext, useState } from "react";
import {
  MANAGE_ITEMS_API,
} from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import Form from "../../../../../Partials/Forms/Form";
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { AuthContext } from "../../../../../../Contexts/GlobalContexts/AuthContext";

function CreatePromotional({ setShowCreateForm, api }) {
  const [image, setImage] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [error, setError] = useState("");

  const { triggerFetch, toggleFetch } = useContext(LayoutContext);
  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();


  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();


    itemData.append("image", image);
    itemData.append("productUrl", productUrl);

    try {
      const response = await axiosInstance.post(
        api,
        itemData
      );
      triggerFetch();
      if (response) {
        setShowCreateForm(false);
      }
    } catch (error) {
      setError("An error occurred while creating the item. Please try again.");
    }
  }
  return (
    <div className="create_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowCreateImage
        >
          Upload Promotional Image (550 x 650)
        </ImageInput>
        <ShortTextInput
          label="Product Url"
          value={productUrl}
          placeholder="Enter Product URL"
          setState={setProductUrl}
        />
        <FormSubmitButton text="Create Promotional" />
      </Form>
    </div>
  );
}

export default CreatePromotional;
