import { Link } from 'react-router-dom'
import logoImg from '../../../../../../../../src/assets/logos/logo.png'
import wideLogoImg from '../../../../../../../../src/assets/logos/gasdum.png'
import { Image } from '../../../../../Elements/Image/Image'

function SidebarLogo({ handleClick, expandSidebar }) {
    return (
        <Link href="/" onClick={handleClick}>
            {
                !expandSidebar
                ?
                <Image
                    imgLink={logoImg}
                    imgAlt={'brand'}
                    className={'logo'}
                    assetOrWeb
                />
                // <p className="logo"> GM</p>
                :
                <Image
                    imgLink={wideLogoImg}
                    imgAlt={'brand'}
                    className={'logo_wide'}
                    assetOrWeb
                />
                // <p className="logo"> Gasdum</p>
            }
        </Link>
    )
}

export default SidebarLogo