import { useGetInfo } from "antopolis-react-utils/hooks";
import { IconThreeDots } from "antopolis-react-icons";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useState } from "react";
import { CHANGE_ORDERS_STATUS_API, ORDERS_API } from "../../../../../Utilities/APIs/OrderAPIs/OrderAPIs";
import { useNavigate, useParams } from "react-router-dom";
import { ShortTextCell } from "../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { RiCheckboxCircleLine, RiCloseCircleLine, RiGiftLine, RiListSettingsLine, RiRepeatLine, RiTruckLine } from "react-icons/ri";
import { CRUDButton, EditButton, ViewButton } from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { useUseOrientation } from "../../../../../Utilities/Hooks/useUseOrientation";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";

import ViewOrder from "./ViewOrder";
import OrderDelivered from "./OrderDelivered";
import CancelOrder from "./CancelOrder";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton";
import { CreateButton } from "../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import CreateOrder from "./CreateOrder";

const statusName = {
    processing: "Order Placed",
    toBeDelivered: "Processing Order",
    shipped: "Shipped Order",
    completed: "Completed Order",
    canceled: "Canceled Order",
}

function OrdersFromStatus() {

    const [showActionButton, setShowActionButton] = useState(false);
    const [showViewSection, setShowViewSection] = useState(false);
    const [showProcessingOrder, setShowProcessingOrder] = useState(false);
    const [showCancelOrder, setShowCancelOrder] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);

    const [toggleFetch, setToggleFetch] = useState(false);

    const [targetID, setTargetID] = useState(null);

    const { portrait } = useUseOrientation()
    const axiosInstance = useAxiosInstance();

    const { status } = useParams();
    const navigate = useNavigate();



    const { info: orders } = useGetInfo({
        axiosInstance: axiosInstance,
        api: ORDERS_API + "getAllOrdersByStatus/" + status,
        toggleFetch
    });


    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    function buttonIcon() {
        switch (status) {
            case 'processing':
                return <RiGiftLine />

            case 'toBeDelivered':
                return <RiTruckLine />

            case 'shipped':
                return <RiCheckboxCircleLine />

            default:
                return <RiRepeatLine />
        }
    }

    async function handleStatusChange(id) {

        switch (status) {
            case 'processing':
                await axiosInstance.put(CHANGE_ORDERS_STATUS_API + 'toBeDeliveredFromProcessing/' + id, '',)
                triggerFetch()
                setShowProcessingOrder(false)
                return

            case 'toBeDelivered':
                await axiosInstance.put(CHANGE_ORDERS_STATUS_API + 'shippedFromToBeDelivered/' + id, '',)
                triggerFetch()
                setShowProcessingOrder(false)
                return

            case 'shipped':
                await axiosInstance.put(CHANGE_ORDERS_STATUS_API + 'completedFromShipped/' + id, '',)
                triggerFetch()
                setShowProcessingOrder(false)
                return

            default:
                return console.log('Status Not Changed')
        }

    }


    return (
        <div className="screen_wrapper">
            <div className="screen_header" >
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton />
                    <h1 className="screen_heading">{statusName[status]}    {orders?.length} in total</h1>
                </div>
                {/* <CreateButton
                    screenTopicSingular={"Order"}
                    setShowCreateForm={setShowCreateForm}
                /> */}
            </div>
            <CRUDTable >
                <CRUDTableHeader>
                    <CRUDth th="Order ID" />
                    <CRUDth th="Customer Name" />
                    <CRUDth th="Order Date" />
                    <CRUDth th="Order Total" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {orders?.length > 0 && orders?.map((order) => (
                        <tr key={order._id}>
                            <ShortTextCell text={order?._id} />
                            <ShortTextCell text={order?.name ? order?.name : order?.customer?.name} />
                            <ShortTextCell text={new Date(order?.createdAt).toLocaleDateString()} />
                            <ShortTextCell text={order?.grandTotal} />

                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === order._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(order._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={order._id}
                                                setTargetID={setTargetID}
                                            />

                                            <CRUDButton handleClick={() => navigate('/main/orderItemsFromOrder/' + order._id)}>
                                                <RiListSettingsLine />
                                            </CRUDButton>
                                            {
                                                (status === 'processing' || status === 'toBeDelivered' || status === 'shipped') &&

                                                <CRUDButton
                                                    handleClick={() => {
                                                        setShowProcessingOrder(true)
                                                        setTargetID(order._id)
                                                    }}
                                                >
                                                    {buttonIcon()}
                                                </CRUDButton>
                                            }
                                            {
                                                (status !== 'completed' && status !== 'canceled') &&
                                                <CRUDButton
                                                    handleClick={() => {
                                                        setShowCancelOrder(true)
                                                        setTargetID(order._id)

                                                    }}
                                                >
                                                    <RiCloseCircleLine />
                                                </CRUDButton>
                                            }
                                        </div>


                                    )}
                                    {portrait && showActionButton && targetID === order._id && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={order._id}
                                                setTargetID={setTargetID}
                                            />

                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </CRUDTable>
            {
                showViewSection &&
                <Modal
                    extraClass={"small"}
                    modalHeading={`View Order`}
                    setShowModalContent={setShowViewSection}
                >
                    <ViewOrder targetID={targetID} />
                </Modal>
            }
            {
                showProcessingOrder &&
                <Modal
                    extraClass={"small"}
                    modalHeading={showProcessingOrder && `Precessing Order`}
                    setShowModalContent={setShowProcessingOrder}
                >
                    <OrderDelivered
                        handleStatusChange={handleStatusChange}
                        targetID={targetID}
                        setShowProcessingOrder={setShowProcessingOrder}
                        status={status} />

                </Modal>
            }
            {
                showCancelOrder &&
                <Modal
                    extraClass={"small"}
                    modalHeading={showCancelOrder && `Cancel Order`}
                    setShowModalContent={setShowCancelOrder}
                >
                    <CancelOrder
                        targetID={targetID}
                        setShowCancelOrder={setShowCancelOrder}
                        triggerFetch={triggerFetch} />
                </Modal>
            }
            {
                showCreateForm &&
                <Modal
                    extraClass={"small"}
                    modalHeading={showCreateForm && `Creat Order`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateOrder
                        api=""
                        setShowCancelOrder={setShowCreateForm}
                        triggerFetch={triggerFetch} />
                </Modal>
            }

        </div>
    );
}

export default OrdersFromStatus;
