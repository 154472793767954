import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPublicInstance from "../../Utilities/Hooks/useAxiosPublicInstance";
import { AuthContext } from "./AuthContext";
import { useAxiosInstance } from "../../Utilities/Hooks/useAxiosInstance";

const LayoutContext = createContext({})


function LayoutContextProvider({ children }) {

    const [isLoading, setIsLoading] = useState(false)
    const [expandSidebar, setExpandSidebar] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);
    const { employee } = useContext(AuthContext)
    const axiosPublicInstance = useAxiosPublicInstance();
    const axiosInstance = useAxiosInstance();
    const [cartItems, setCartItems] = useState([]);
    const [selectedDeliveryFee, setSelectedDeliveryFee] = useState(0);
    const [selectedVatFee, setSelectedVatFee] = useState(0)

    const [total, setTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [vat, setVat] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [promoCode, setPromoCode] = useState(null);
    const [applyedCode, setApplyedCodel] = useState(null);

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }
    useEffect(() => {
        async function fetchAndSetEcommerceSetting() {

            const { data } = await axiosPublicInstance.get('ecommerceSettings/getEcommerceSettingsData');
            setSelectedDeliveryFee(data?.deliveryFees[0]?.amount);
            setSelectedVatFee(data?.vats[0]?.amount);
        }
        fetchAndSetEcommerceSetting();

    }, []);
    useEffect(() => {
        const localStorageCart = JSON.parse(localStorage.getItem("cart"));
        if (localStorageCart) {
            setCartItems(localStorageCart);
            triggerFetch()
        }
    }, []);
    function updateCartItems(items) {
        setCartItems(items);
        localStorage.setItem("cart", JSON.stringify(items));
        triggerFetch()
    }

    function addToCart(id, price, name, image, isDiscount, discountAmount) {
        const isExistedItem = cartItems.find((cart) => cart.item === id);
        console.log("id, price, name, image, isDiscount, discountAmount", id, price, name, image, isDiscount, discountAmount)
        if (!isExistedItem) {
            const updatedCartItems = [
                ...cartItems,
                {
                    item: id,
                    name: name,
                    image: image,
                    quantity: 1,
                    unitPrice: isDiscount ? (price - ((price * discountAmount) / 100)) : price,
                    total: isDiscount ? (price - ((price * discountAmount) / 100)) : price,
                },
            ];
            updateCartItems(updatedCartItems);
        }
        return;
    }
    function handleIncrease(id) {
        const updatedCartItems = cartItems.map((item) => {
            if (item.item === id) {
                return {
                    ...item,
                    quantity: item.quantity + 1,
                    total: item.unitPrice * (item.quantity + 1),
                };
            }
            return item;
        });
        updateCartItems(updatedCartItems);
        triggerFetch()
    }

    function handleDecrease(id) {
        const updatedCartItems = cartItems.map((item) => {
            if (item.item === id && item.quantity > 1) {
                return {
                    ...item,
                    quantity: item.quantity - 1,
                    total: item.unitPrice * (item.quantity - 1),
                };
            }
            return item;
        });
        updateCartItems(updatedCartItems);
        triggerFetch()
    }

    function handleRemove(id) {
        const updatedCartItems = cartItems.filter((item) => item.item !== id);
        updateCartItems(updatedCartItems);
    }
    useEffect(() => {
        let cartItemsTotal = 0;
        if (cartItems.length > 0) {
            cartItemsTotal = cartItems.reduce(
                (previousValue, currentValue) => previousValue + currentValue.total,
                0
            );
        }
        setTotal(cartItemsTotal);

        if (applyedCode) {
            if (applyedCode?.discountType === "percentage") {
                const discountAmount = (total * applyedCode?.discountAmount) / 100
                setDiscount(discountAmount)
            } else {
                const discountAmount = applyedCode?.discountAmount
                setDiscount(discountAmount)
            }
        }

        const vatAmount = (cartItemsTotal * selectedVatFee) / 100;
        setVat(vatAmount);

        const deliveryFeeAmount = cartItemsTotal === 0 ? 0 : selectedDeliveryFee;
        setDeliveryFee(deliveryFeeAmount);

        const subTotalAmount = ((cartItemsTotal + vatAmount + deliveryFeeAmount) - discount);
        setSubtotal(subTotalAmount);

        setGrandTotal(subTotalAmount);

    }, [cartItems, selectedDeliveryFee, selectedVatFee, discount, applyedCode]);
    const handleApplyPromoCode = () => {
        async function fetchAndSetApplyedData() {
            try {
                const { data } = await axiosInstance.get(`adminOrder/ecommerceSettings/getPromoCode/${promoCode}`);
                setApplyedCodel(data);
                // toast.success("Applied Promo code successfully")
            } catch (error) {
                if (!employee) {
                    // toast.error("Please Log In to Use Promo Code!")
                } else {
                    // toast.error("The Promo code you entered is not vaild")
                }

            }
        }
        fetchAndSetApplyedData();
    }
    return (
        <LayoutContext.Provider
            value={{
                isLoading,
                setIsLoading,
                expandSidebar,
                setExpandSidebar,
                toggleFetch,
                triggerFetch,
                cartItems,
                setCartItems,
                addToCart,
                handleIncrease,
                handleDecrease,
                handleRemove,
                selectedDeliveryFee,
                selectedVatFee,
                total,
                discount,
                deliveryFee,
                subtotal,
                vat,
                grandTotal,
                promoCode,
                setPromoCode,
                handleApplyPromoCode
            }}
        >
            {children}
        </LayoutContext.Provider>
    )
}

export {
    LayoutContextProvider,
    LayoutContext,
}