import { useGetInfo } from "antopolis-react-utils/hooks";
import './Dashboard.css';
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import StatsCard from "./StatsCard/StatsCard";
import { takaFormatter } from "../../../../Utilities/Hooks/TakaFormatter";
import { CRUDTable } from "../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ImageCell } from "../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import DashboardIcon1 from "../../../../assets/dashboard/dashboard-icon-1.png"
import DashboardIcon2 from "../../../../assets/dashboard/dashboard-icon-2.png"
import DashboardIcon3 from "../../../../assets/dashboard/dashboard-icon-3.png"
import DashboardIcon4 from "../../../../assets/dashboard/dashboard-icon-4.png"
import DashboardIcon5 from "../../../../assets/dashboard/dashboard-icon-5.png"
import DashboardIcon6 from "../../../../assets/dashboard/dashboard-icon-6.png"
import DashboardIcon7 from "../../../../assets/dashboard/dashboard-icon-7.png"
import DashboardIcon8 from "../../../../assets/dashboard/dashboard-icon-8.png"
import DashboardIcon9 from "../../../../assets/dashboard/dashboard-icon-9.png"
import SalesChart from "./SalesChart/SalesChart";

function Dashboard() {

  const axiosInstance = useAxiosInstance();

  const { info: statistics } = useGetInfo({
    axiosInstance: axiosInstance,
    api: 'stats/getOrderStatistics'
  });
  console.log("statistics", statistics)
  return (
    <div className="dashboard">
      <div className="dashboard-top">
        <div className="top-contaner">
          <div className="stats-container">
            {/* <div className="stats-card">
              <h1>{statistics?.totalOrder}</h1>
              <h2>Total Order</h2>
            </div> */}
            <StatsCard
              title="Total Order"
              value={`${statistics?.totalOrder}`}
              icon={DashboardIcon7}
            />
            <StatsCard
              title="Order Placed"
              value={`${statistics?.orderPlaced}`}
              icon={DashboardIcon1}
            />
            
           
            <StatsCard
              title="Completed"
              value={statistics?.orderCompletions}
              icon={DashboardIcon4}
            />
            <StatsCard
              title="Processing"
              value={`${(statistics?.orderToBeDelivered)}`}
              icon={DashboardIcon2}
            />
            <StatsCard
              title="Canceled"
              value={statistics?.orderCanceled}
              icon={DashboardIcon5}
            />
             <StatsCard
              title="Shipped"
              value={statistics?.orderShipped}
              icon={DashboardIcon3}
            />

            {/* <StatsCard
              title="Return Orders"
              value={statistics?.orderReturns}
              icon={DashboardIcon7}
            />
            <StatsCard
              title="Daily Orders"
              value={statistics?.dailyOrders}
              icon={DashboardIcon6}
            />

            <StatsCard
              title="Daily Pending Orders"
              value={statistics?.dailyPendingOrders}
              icon={DashboardIcon6}
            /> */}

          </div>
        </div>
        <div className="top-contaner">
          <div className="stats-container">
            <div className="stats-card stats-card-first">
              <h1>{statistics?.totalSales === "undefined" || statistics?.totalSales === '৳ NaN' ? 0 : takaFormatter(statistics?.totalSales)}</h1>
              <h2>Total Sales</h2>
            </div>
            <StatsCard
              title="Daily Sales"
              value={`${takaFormatter(statistics?.dailySales)}`}
              icon={DashboardIcon8}
            />
            <StatsCard
              title="Monthly Sales"
              value={`${takaFormatter(statistics?.monthlySales)}`}
              icon={DashboardIcon9}
            />
          </div>
          <SalesChart />
        </div>
      </div>



      <div>
        <div className="" style={{ marginTop: "30px" }}>
          <h1 className="screen_heading">
            Top Selling Products
          </h1>
          <CRUDTable>
            <CRUDTableHeader>
              <CRUDth th="Image" />
              <CRUDth th="Name" />
              <CRUDth th="Price" />
              <CRUDth th="Stock" />
            </CRUDTableHeader>
            <tbody>
              {statistics?.items?.map((item) => (
                <tr key={item._id}>
                  <ImageCell imgSrc={item?.image} imgAlt={item.name} />
                  <td>{item.name}</td>
                  <td>{takaFormatter(item.price)}</td>
                  <td>{item?.quantity}</td>

                </tr>
              ))}
            </tbody>
          </CRUDTable>
        </div>



        <div style={{ marginTop: "30px" }}>
          <h1 className="screen_heading">
            Low Stock Products
          </h1>
          <CRUDTable>
            <CRUDTableHeader>
              <CRUDth th="Image" />
              <CRUDth th="Name" />
              <CRUDth th="Price" />
              <CRUDth th="Stock" />
            </CRUDTableHeader>
            <tbody>
              {statistics?.lowStockProducts?.map((item) => (
                <tr key={item._id}>
                  <ImageCell imgSrc={item?.image} imgAlt={item.name} />
                  <td>{item.name}</td>
                  <td>{takaFormatter(item.price)}</td>
                  <td>{item?.quantity}</td>

                </tr>
              ))}
            </tbody>
          </CRUDTable>
        </div>
      </div>



    </div>
  );
}

export default Dashboard;
