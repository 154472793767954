import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { SUBCATEGORIES } from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";

function ViewSubcategory({ targetID }) {
  const axiosInstance = useAxiosInstance();

  const { info: subCategory } = useGetInfo({
    axiosInstance: axiosInstance,
    api: SUBCATEGORIES + "singleSubCategory/" + targetID,
  });

  return (
    <div className="crud_view_content">
    {subCategory && (
      <>
        <h1>Name</h1>
        <p>{subCategory.name}</p>

        <h1>Description</h1>
        <p>{subCategory.description}</p>

        {/* <h1>Precedence</h1>
        <p>{subCategory.precedence}</p> */}

       
      </>
    )}
  </div>
  );
}

export default ViewSubcategory;
