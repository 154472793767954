import { useContext, useEffect, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Forms/Form"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import SearchSelectField from "../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField"
import { CATEGORIES_API, SUBCATEGORIES } from "../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs"
import { useGetInfo } from "antopolis-react-utils/hooks"
import { LayoutContext } from "../../../../../Contexts/GlobalContexts/LayoutContext"
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"

function CreateFeaturedItem({ api, setShowCreateForm, triggerFetch }) {
    const { toggleFetch } = useContext(LayoutContext)
    const [precedence, setPrecedence] = useState(0);
    const [allItems, setAllItems] = useState([])
    const [type, setType] = useState("Bathware");
    const [categories, setCategories] = useState(null)
    const [category, setCategory] = useState(null)
    const [subCategories, setSubCategories] = useState(null)
    const [subCategory, setSubCategory] = useState(null)
    const [item, setItem] = useState(null)
    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = {
            item,
            precedence
        }

        const response = await axiosInstance.post(api + "/createFeaturedItem", itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
            setAllItems([])
            setCategory("all")
            setItem(null)
        }
    }

    const typeDatas = [
        {
            name: "Bathware",
            value: "Bathware"
        },
        {
            name: "Kitchenware",
            value: "Kitchenware"
        }
    ]

    useEffect(() => {
        const hendleGetCategoryByType = async () => {

            const response = await axiosInstance.get(`${CATEGORIES_API}getAllCategories?type=${type}`)

            if (response) {
                setCategories(response?.data)

            }


        }
        hendleGetCategoryByType()

    }, [type])

    useEffect(() => {
        const hendleGetSubCategoriesByCategory = async () => {

            const response = await axiosInstance.get(`${SUBCATEGORIES}singleSubCategoriesByCategory/${category}`)

            if (response) {
                setSubCategories(response?.data)

            }


        }
        category && hendleGetSubCategoriesByCategory()

    }, [category])

    useEffect(() => {
        const hendleGetItemsByCategory = async () => {

            const response = await axiosInstance.get(`adminOrder/getItemsByCategory?type=${type}&category=${category !== null ? category : ""}&subCategory=${subCategory !== null ? subCategory : ""}`)

            if (response) {
                setAllItems(response?.data)

            }


        }
        hendleGetItemsByCategory()

    }, [type, category, subCategory])
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <SearchSelectField
                    label="Select Type"
                    optionLabel="name"
                    optionValue="value"
                    value={type}
                    options={typeDatas}
                    placeholder="Select Type"
                    setState={setType}
                />
                <SearchSelectField
                    label="Categories"
                    optionLabel="name"
                    optionValue="_id"
                    value={category}
                    options={categories}
                    placeholder="Enter Category"
                    setState={setCategory}
                />
                {category !== null && <SearchSelectField
                    label="Sub Categories"
                    optionLabel="name"
                    optionValue="_id"
                    value={subCategory}
                    options={subCategories}
                    placeholder="Enter sub categories"
                    setState={setSubCategory}
                />}
                <SearchSelectField
                    label="Products"
                    optionLabel="name"
                    optionValue="_id"
                    value={item}
                    options={allItems}
                    placeholder="Enter Product"
                    setState={setItem}
                />
                <NumberInput
                    label="Precedence"
                    value={precedence}
                    placeholder="Enter Precedence"
                    setState={setPrecedence}
                />

                <FormSubmitButton text="Create Featured Items" />
            </Form>
        </div>
    )
}

export default CreateFeaturedItem