import { useContext, useState } from "react";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../../Contexts/GlobalContexts/LayoutContext";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import Form from "../../../../Partials/Forms/Form";
import SwitchInput from "../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";

function CreateSocialLink({ api, targetID, setShowCreateForm }) {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [visibility, setVisibility] = useState(true);
  const [error, setError] = useState("");

  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = {
      name,
      link,
      visibility,
    };

    try {
      const response = await axiosInstance.post(api + "createSocailLink", itemData);
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
      }
    } catch (error) {
      setError(
        "An error occurred while creating the social link. Please try again."
      );
    }
  }

  return (
    <div className="create_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label="Name"
          value={name}
          placeholder="Enter Name"
          setState={setName}
        />
        <ShortTextInput
          label="Link"
          value={link}
          placeholder="Enter Link"
          setState={setLink}
        />
        <SwitchInput
          label="Visibility"
          toggleSwitch={() => setVisibility(!visibility)}
          checked={visibility}
        />
        <FormSubmitButton text="Create Social Link" />
      </Form>
    </div>
  );
}

export default CreateSocialLink;
