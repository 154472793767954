import { useState } from "react"
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../../Partials/Forms/Form"
import EmailInput from "../../../../../Partials/Forms/FormInputs/EmailInput/EmailInput"
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"

function CreateInvite({employee,api, setShowCreateForm, triggerFetch}) {

    const [email, setEmail] = useState('')
    const [isEmailValid, setIsEmailValid] = useState(false);
    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault() 
        const itemData = {}      
        itemData.email = email 
        const response = await axiosInstance.post(api+"employeeinvite", itemData, )
        if (response) {
            setEmail('')
            setShowCreateForm(false)
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit}>
            <EmailInput
                label={'Email'}
                value={email}
                placeholder={'Please Enter an Email'}
                setState={setEmail}
                setIsValid={setIsEmailValid}
            />
            <FormSubmitButton text='Send Invite to Email' />
        </Form>
    )

}

export default CreateInvite