import { useGetInfo } from "antopolis-react-utils/hooks";
import { MANAGE_ITEMS_API } from "../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useContext } from "react";
import { LayoutContext } from "../../../../../Contexts/GlobalContexts/LayoutContext";
import Form from "../../../../Partials/Forms/Form";
import Select from "react-select";
import makeAnimated from 'react-select/animated';

function CreateOrder({ api, setShowCreateForm, triggerFetch }) {
    const animatedComponents = makeAnimated();
    const axiosInstance = useAxiosInstance();
    const { toggleFetch } = useContext(LayoutContext);
    const { info: allItems } = useGetInfo({
        axiosInstance: axiosInstance,
        api: MANAGE_ITEMS_API + "getAllItems",
        toggleFetch,
    });
    async function handleSubmit(e) {
        e.preventDefault()
    }
    const ots = allItems && allItems?.map((option) => {
        return {
          label: option?.name,
          value: option?._id,
        };
      });
    console.log("allItems", allItems)
    return (<>
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div className="input_group">
                    <label className="input_field_label caption bold">
                        <strong>Product</strong>
                    </label>
                    <Select
                        defaultValue={allItems}
                        options={ots}
                        components={animatedComponents}
                        isMulti
                        // onChange={(e) => setState(e.value)}
                    />
                </div>
            </Form>
        </div>
    </>)
}
export default CreateOrder