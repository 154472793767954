import { useEffect, useState } from "react"
import EmailInput from "../../../../Partials/Forms/FormInputs/EmailInput/EmailInput"
import LongTextInput from "../../../../Partials/Forms/FormInputs/LongTextInput/LongTextInput"
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"
import SearchSelectField from "../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import { AREAS_API, DISTRICTS_API, DIVISIONS_API } from "../../../../../Utilities/APIs/AddressApIs/AddressApIs"

function CustomerInfo({
    name,
    setName,
    email,
    setEmail,
    setIsValid,
    phoneNumber,
    setPhoneNumber,
    fullAddress,
    setFullAddress,
    district,
    setDistrict,
    division,
    setDivision,
    area,
    setArea,
    notes,
    setNotes }) {
    const axiosInstance = useAxiosInstance();
    const [divisions, setDivisions] = useState(null);
    const [districts, setDistricts] = useState(null);
    const [selectedDistricts, setSelectedDistricts] = useState(null)
    const [areas, setAreas] = useState(null);

    async function fetchAndSetDivisions() {
        const { data } = await axiosInstance.get(DIVISIONS_API);
        setDivisions(data);
    }
    async function fetchAndSetDistricts() {
        const { data } = await axiosInstance.get(DISTRICTS_API);

        setDistricts(data);
    }
    useEffect(() => {
        fetchAndSetDivisions();
        fetchAndSetDistricts();
        // fetchAndSetAreas();
    }, []);
    useEffect(() => {
        if (selectedDistricts && districts) {
            const selectedData = districts.find(
                (districtToFind) => districtToFind._id === selectedDistricts
            );
            setDistrict(selectedData || null);


            const selectedDivision = divisions.find(
                (districtToFind) => districtToFind._id === selectedData.division
            );
            setDivision(selectedDivision)
            console.log("selectedData", selectedDivision)

        }

    }, [selectedDistricts]);


    useEffect(() => {
        if (district) {
            async function fetchAndSetAreas() {
                const { data } = await axiosInstance.get(
                    AREAS_API + "getAreasFromDistrict/" + district?._id
                );
                // const areasData = await areasRes.json()
                setAreas(data);
            }

            fetchAndSetAreas();
        }
    }, [district, axiosInstance]);
    return (<>
        <div className="details-inputs">
            <ShortTextInput
                label="Name"
                value={name}
                placeholder="Enter Name"
                setState={setName}
            />
            <EmailInput
                label='Email'
                value={email}
                placeholder='Please Enter  Email'
                setState={setEmail}
                setIsValid={setIsValid}
            />
            <NumberInput
                label="Phone Number"
                value={phoneNumber}
                setState={setPhoneNumber}
                placeholder="Enter Phone Number"
            />
        </div>
        <div className="details-inputs">
            <ShortTextInput
                label="Full Address"
                value={fullAddress}
                placeholder="Enter Adress"
                setState={setFullAddress}
            />
        </div>
        <div className="details-inputs">
            <SearchSelectField
                label="District"
                optionLabel="name"
                optionValue="_id"
                value={selectedDistricts}
                options={districts}
                placeholder="Enter District"
                setState={setSelectedDistricts}
            />
            <div className="input_group">
                <label className="input_field_label caption bold">Division</label>
                <input
                    className='input_field'
                    type="text"
                    value={division?.name}
                    placeholder="Enter Division"
                    readOnly
                />
            </div>
            <SearchSelectField
                label="Area"
                optionLabel="name"
                optionValue="_id"
                value={area}
                options={areas}
                placeholder="Enter Area"
                setState={setArea}
            />
        </div>
        <div className="details-inputs">
            <LongTextInput
                label="Short Note"
                value={notes}
                placeholder="Enter note"
                setState={setNotes} />
        </div>
    </>)
}
export default CustomerInfo