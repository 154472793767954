import { useState } from "react";
import { useUseOrientation } from "../../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { CreateButton } from "../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ImageCell } from "../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { IconThreeDots } from "antopolis-react-icons";
import { ArchiveButton, EditButton, ViewButton } from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";
import CreateNewsAndEvents from "./CreateNewsAndEvents";
import UpdateNewsAndEvents from "./UpdateNewsAndUpdates";
import ViewNewsAndEvent from "./ViewNewsAndEvents";
import ArchiveItem from "../../../../Partials/Layout/ArchiveItem/ArchiveItem";
import FilterSelect from "../../../../Partials/Forms/FilterSelect/FilterSelect";

function NewsAndEvents({ api }) {

    const [showViewSection, setShowViewSection] = useState(false);
    const [showActionButton, setShowActionButton] = useState(false);
    const [showArchiveSection, setShowArchiveSection] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);
    const [isActive, setIsActive] = useState(null)
    const [filter, setFilter] = useState("active");

    const [targetID, setTargetID] = useState(null);

    const { portrait } = useUseOrientation()
    const axiosInstance = useAxiosInstance();

    const { info: newsAndEvent } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + `/getNewsAndEvents?filter=${filter}`,
        toggleFetch
    });

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <div className="screen_wrapper">
            <div className="screen_header" >
                <h1 className="screen_heading">
                    News And Events ({newsAndEvent?.length} in total)
                </h1>
                <FilterSelect filter={filter} setFilter={setFilter} />
                <CreateButton
                    screenTopicSingular={"News And Event"}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable >
                <CRUDTableHeader>
                    <CRUDth th="Image" />
                    <CRUDth th="Title" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {newsAndEvent?.map((newsAndEvent) => (
                        <tr key={newsAndEvent._id}>
                            <ImageCell imgSrc={newsAndEvent?.image} imgAlt={newsAndEvent?.title} />
                            <td>{newsAndEvent?.title}</td>
                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === newsAndEvent._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(newsAndEvent._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && showActionButton && (
                                        <div className="action_buttons">
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={newsAndEvent._id}
                                                setTargetID={setTargetID}
                                            />
                                        </div>
                                    )}

                                    {!portrait && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={newsAndEvent._id}
                                                setTargetID={setTargetID}
                                            />
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={newsAndEvent._id}
                                                setTargetID={setTargetID}
                                            />
                                            <ArchiveButton
                                                setShowArchiveForm={setShowArchiveSection}
                                                targetID={newsAndEvent._id}
                                                setTargetID={setTargetID}
                                                isActive={newsAndEvent?.isActive}
                                                setActive={setIsActive} />
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    )
                    )}
                </tbody>

            </CRUDTable>

            {showCreateForm && showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create News And Events`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateNewsAndEvents
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}
                        api={api + "createNewsAndEvent"}
                    />

                </Modal>
            )}


            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Contact Info`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateNewsAndEvents
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                        api={api}
                    />
                </Modal>
            )}

            {showViewSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`View News And Event`}
                    setShowModalContent={setShowViewSection}
                >
                    <ViewNewsAndEvent targetID={targetID} api={api} />
                </Modal>
            )}

            {showArchiveSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Archive Discount`}
                    setShowModalContent={setShowArchiveSection}
                >
                    <ArchiveItem
                        api={`${api}archiveNewsAndEvent/`}
                        isActive={isActive}
                        targetID={targetID}
                        setShowModal={() => { }}
                        setShowActionButton={setShowArchiveSection}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}
        </div>
    );
}

export default NewsAndEvents;
