import { useEffect, useState } from "react"
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Forms/Form"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";

function UpdateFeaturedItem({ api, setShowUpdateForm, triggerFetch, targetID }) {

    const [precedence, setPrecedence] = useState(0);
    const axiosInstance = useAxiosInstance()

    const { info: featuredItem } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + 'singleFeaturedItem/' + targetID,
    })

    useEffect(() => {
        if (featuredItem) {
            setPrecedence(featuredItem.precedence)
        }
    }, [featuredItem])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = {
            precedence,
        }

        const response = await axiosInstance.patch(api + 'singleFeaturedItem/' + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <NumberInput
                    label="Precedence"
                    value={precedence}
                    placeholder="Enter Precedence"
                    setState={setPrecedence}
                />

                <FormSubmitButton text="Update Featured Item" />[]
            </Form>
        </div>
    )
}

export default UpdateFeaturedItem