import { useContext, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"
import { DEALER_API } from '../../../../../Utilities/APIs/DealerDivisionAPIs/DealerDivisionAPIs';
import EmailInput from "../../../../Partials/Forms/FormInputs/EmailInput/EmailInput"


function CreateDealer({ setShowCreateForm, triggerFetch , dealerAreaID}) {
    
    const [shopName, setShopName] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')

    const [isEmailValid, setIsEmailValid] = useState(false);

    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()

        itemData.append('shopName', shopName)
        itemData.append('name', name)
        itemData.append('email', email)
        itemData.append('phone', phone)
        itemData.append('address', address)
        itemData.append('dealerArea', dealerAreaID)

        const response = await axiosInstance.post(DEALER_API + 'createDealer', itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <ShortTextInput
                    label={`Shop Name`}
                    value={shopName}
                    placeholder={`Enter Shop Name`}
                    setState={setShopName}

                />
                <ShortTextInput
                    label={`Name`}
                    value={name}
                    placeholder={`Enter Name`}
                    setState={setName}

                />
                <EmailInput
                    label={`Email`}
                    value={email}
                    placeholder={`Enter Email`}
                    setState={setEmail}
                    setIsValid={setIsEmailValid}


                />
                <ShortTextInput
                    label={`Phone`}
                    value={phone}
                    placeholder={`Enter Phone`}
                    setState={setPhone}

                />
                <ShortTextInput
                    label={`Address`}
                    value={address}
                    placeholder={`Enter Address`}
                    setState={setAddress}

                />

                <FormSubmitButton text="Create Dealer" />
            </Form>
        </div >
    )
}

export default CreateDealer