import { useParams } from "react-router-dom";
import { ScreenHolder } from "../../../../../Partials/Layout/ScreenHolder/ScreenHolder";
import { NavCardList } from "../../../../../Partials/Layout/NavCardList/NavCardList";
import { NavCard } from "../../../../../Partials/Layout/NavCardList/NavCard/NavCard";
import { RiFacebookBoxFill, RiImage2Fill, RiMap2Line, RiPercentFill, RiImageAddFill, RiSettings2Fill, RiColorFilterLine, RiPagesLine } from 'react-icons/ri'
function ItemAssets() {
    const { itemId } = useParams();
    return (
        <ScreenHolder>
            <NavCardList numOfCards={'six'}>
                <NavCard cardName={'item Large Images'} navCardLink={`/main/itemLargeImages/${itemId}`} ><RiImage2Fill /></NavCard>
                <NavCard cardName={'Items Images'} navCardLink={`/main/itemImages/${itemId}`} ><RiImageAddFill /></NavCard>
                <NavCard cardName={'Item Benefits'} navCardLink={`/main/itemBenefits/${itemId}`} ><RiSettings2Fill /></NavCard>
                <NavCard cardName={'Item Colors'} navCardLink={`/main/itemColors/${itemId}`} ><RiColorFilterLine /></NavCard>
                <NavCard cardName={'Related Items'} navCardLink={`/main/relatedItems/${itemId}`} ><RiPagesLine /></NavCard>
                <NavCard cardName={'Recommended Items'} navCardLink={`/main/recommendedItems/${itemId}`} ><RiPagesLine /></NavCard>
            </NavCardList>
        </ScreenHolder>
    )
}

export default ItemAssets;