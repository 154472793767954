import { useState, useContext } from "react";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../../Partials/Forms/Form";
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import ShortTextInput from "../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";

function CreateItemBenefits({ itemId, setShowCreateForm,api }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const assetData = new FormData();
    assetData.append("item", itemId);
    assetData.append("title", title);
    assetData.append("description", description);

    try {
      const response = await axiosInstance.post(
        api + 'createItemBenefit',
        assetData
      );
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
        setTitle("")
        setDescription("")
      }
    } catch (error) {
      setError("An error occurred while creating the asset. Please try again.");
    }
  }

  return (
    <div className="create_asset_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label="Title"
          value={title}
          placeholder="Enter Title"
          setState={setTitle}
        />
        <ShortTextInput
          label="Description"
          value={description}
          placeholder="Enter Description"
          setState={setDescription}
        />

        <FormSubmitButton text="Create Item Benefit" />
      </Form>
    </div>
  );
}

export default CreateItemBenefits;
