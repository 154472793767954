import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { ImageCell } from "../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { MANAGE_ITEMS_API } from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";

function ViewItem({ targetID, api }) {
  const axiosInstance = useAxiosInstance();

  const { info: item } = useGetInfo({
    axiosInstance: axiosInstance,
    api: `${MANAGE_ITEMS_API}/singleItem/${targetID}`,
  });

  return (
    <div className="crud_view_content">
      {item && (
        <>
          <h1>Type</h1>
          <h1>{item.type}</h1>

          <h1>Name</h1>
          <h1>{item.name}</h1>

          <h1>Description</h1>
          <p>{item.description}</p>

          {/* <h1>Category</h1>
          <p>{item.category}</p> */}

          <h1>Price</h1>
          <p>{item.price}</p>

          <h1>Image</h1>
          <ImageCell
            imgSrc={item.image}
            imgAlt={item.name}
            className="item-image"
          />
        </>
      )}
    </div>
  );
}

export default ViewItem;
