import slugify from "react-slugify";
import { useContext, useEffect, useState } from "react";

import { useGetInfo } from "antopolis-react-utils/hooks";
import { COLLECTIONS_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import ShortTextInput from "../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../../../../Partials/Forms/Form";
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";

function UpdateCollection({ setShowUpdateForm, targetID }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  const { info: collection } = useGetInfo({
    axiosInstance: axiosInstance,
    api: COLLECTIONS_API + "/singleCollection/" + targetID,
  });

  useEffect(() => {
    if (collection) {
      setName(collection.name);
      setDescription(collection.description);

    }
  }, [collection]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = {
      name,
      description,
      slug : slugify(name),
    };

    try {
      const response = await axiosInstance.patch(
        COLLECTIONS_API + "/singleCollection/" + targetID,
        itemData
      );
      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
      }
    } catch (error) {
      setError(
        "An error occurred while updating the social link. Please try again."
      );
    }
  }

  return (
    <div className="crate_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label="Name"
          value={name}
          placeholder="Enter Name"
          setState={setName}
        />
        <ShortTextInput
          label="Description"
          value={description}
          placeholder="Enter Description"
          setState={setDescription}
        />
        <ShortTextInput
          label="Slug"
          value={slugify(name)}
          placeholder="Enter Slug"

        />

        <FormSubmitButton text="Update Collection" />
      </Form>
    </div>
  );
}

export default UpdateCollection;
