import { FEATURED_ITEM_API, ITEM_BENEFITS_API, ITEM_COLORS_API, ITEM_LARGE_IMAGES_API, PROMOTIONAL_API, RECOMMENDED_ITEM_API, RELATED_ITEM_API } from "../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import {
  ALL_EMPLOYEE_API,
  COLORS_API,
  CONTACT_INFOS_API,
  CUSTOMERS_API,
  DISCOUNT_API,
  DISCOUNT_FEE_API,
  EMPLOYEES_API,
  HERO_SLIDERS_API,
  NEWS_AND_EVENTS_API,
  PROMO_CODE_API,
  SOCIAL_LINKS_API,
  TESTIMONIALS_API,
  VAT_API
} from "../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs";
import Dashboard from "./Dashboard/Dashboard";
import DealerAreas from "./DealerLocations/DealerAreas/DealerAreas";
import DealerDivisions from "./DealerLocations/DealerDivisions/DealerDivisions";
import Dealers from "./DealerLocations/Dealers/Dealers";
import Colors from "./EcommercesSettings/Colors/Colors";
import DeliveryFee from "./EcommercesSettings/DeliveryFee/DeliveryFee";
import Discount from "./EcommercesSettings/Discount/Discount";
import EcommercesSettings from "./EcommercesSettings/EcommercesSettings";
import PromoCode from "./EcommercesSettings/PromoCode/PromoCode";
import VAT from "./EcommercesSettings/VAT/VAT";
import AllItem from "./Items/ItemGroup/AllItem/AllItem";
import Category from "./Items/ItemGroup/Category/Category";
import AllCollection from "./Items/ItemGroup/Collection/AllCollection/AllCollection";
import CollectionGroup from "./Items/ItemGroup/Collection/CollectionGroup";
import ItemCollection from "./Items/ItemGroup/Collection/ItemCollection/ItemCollection";
import NavCollection from "./Items/ItemGroup/Collection/NavCollection/NavCollection";
import ItemAssets from "./Items/ItemGroup/ItemAssets/ItemAssets";
import ItemBenefits from "./Items/ItemGroup/ItemAssets/ItemBenefits/ItemBenefits";
import ItemColors from "./Items/ItemGroup/ItemAssets/ItemColors/ItemColors";
import ItemImages from "./Items/ItemGroup/ItemAssets/ItemImages/ItemImages";
import ItemLargeImages from "./Items/ItemGroup/ItemAssets/ItemLargeImages/ItemLargeImages";
import RecommendedItems from "./Items/ItemGroup/ItemAssets/RecommendedItems/RecommendedItems";
import RelatedItems from "./Items/ItemGroup/ItemAssets/RelatedItems/RelatedItems";
import AllPromotionals from "./Items/ItemGroup/Promotional/AllPromotionals";
import Subcategories from "./Items/ItemGroup/Subcategories/Subcategories";
import Items from "./Items/Items";
import AdminOrder from "./Orders/AdminOrder/AdminOrder";
import OrderItems from "./Orders/OrdersFromStatus/OrderItems/OrderItems";
import OrdersFromStatus from "./Orders/OrdersFromStatus/OrdersFromStatus";
import OrdersScreen from "./Orders/OrdersScreen";
import SaleReports from "./Reports/SaleReports";
import AddressesFromCustomer from "./Users/Customers/AddressesFromCustomer/AddressesFromCustomer";
import Customers from "./Users/Customers/Customers";
import OrdersFromCustomer from "./Users/Customers/OrdersFromCustomer/OrdersFromCustomer";
import WishlistFromCustomer from "./Users/Customers/WishlistFromCustomer/WishlistFromCustomer";
import EmployeeScreen from "./Users/EmployeeScreen/EmployeeScreen";
import Employees from "./Users/EmployeeScreen/Employees/Employees";
import InviteEmployees from "./Users/EmployeeScreen/InviteEmployees/InviteEmployees";
import Users from "./Users/Users";
import Blogs from "./WebsiteContent/Blog/Blogs";
import ContactInfos from "./WebsiteContent/ContactInfos/ContactInfos";
import ContentSliders from "./WebsiteContent/ContentSlider/ContentSliders";
import FeaturedItems from "./WebsiteContent/FeaturedItems/FeaturedItems";
import HeroSlider from "./WebsiteContent/HeroSlider/HeroSlider";
import NewsAndEvents from "./WebsiteContent/NewsAndEvents/NewsAndEvents";
import SocialLinks from "./WebsiteContent/SocialLinks/SocialLinks";
import Testimonial from "./WebsiteContent/Testimonial/Testimonial";
import WebsiteContent from "./WebsiteContent/WebsiteContent";

const MainRoutes = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "websiteContent",
    element: <WebsiteContent />,
  },
  {
    path: "promotional",
    element: <AllPromotionals api={PROMOTIONAL_API}/>,
  },
  {
    path: "items",
    element: <Items />,
  },
  {
    path: "items/allItems",
    element: <AllItem />,
  },
  {
    path: "itemAssets/:itemId",
    element: <ItemAssets />,
  },
  {
    path: "itemLargeImages/:itemId",
    element: <ItemLargeImages api={ITEM_LARGE_IMAGES_API} />,
  },
  {
    path: "itemImages/:itemId",
    element: <ItemImages />,
  },
  {
    path: "itemBenefits/:itemId",
    element: <ItemBenefits api={ITEM_BENEFITS_API} />,
  },
  {
    path: "itemColors/:itemId",
    element: <ItemColors api={ITEM_COLORS_API} />,
  },
  {
    path: "relatedItems/:itemId",
    element: <RelatedItems api={RELATED_ITEM_API} />,
  },
  {
    path: "recommendedItems/:itemId",
    element: <RecommendedItems api={RECOMMENDED_ITEM_API} />,
  },
  {
    path: "category",
    element: <Category />,
  },
  {
    path: "subCategories/:categoryID",
    element: <Subcategories />,
  },
  {
    path: "collection",
    element: <CollectionGroup />,
  },
  {
    path: "collection/allCollection",
    element: <AllCollection />,
  },
  {
    path: "collection/navCollection",
    element: <NavCollection />,
  },
  {
    path: "itemCollections/:collectionID",
    element: <ItemCollection />,
  },
  {
    path: "heroSliders",
    element: <HeroSlider api={HERO_SLIDERS_API} />,
  },
  {
    path: "contentSliders",
    element: <ContentSliders />,
  },
  {
    path: "testimonials",
    element: <Testimonial api={TESTIMONIALS_API} />,
  },
  {
    path: "contactInfos",
    element: <ContactInfos api={CONTACT_INFOS_API} />,
  },
  {
    path: "featuredItems",
    element: <FeaturedItems api={FEATURED_ITEM_API} />,
  },
  {
    path: "newsAndEvents",
    element: <NewsAndEvents api={NEWS_AND_EVENTS_API} />,
  },
  {
    path: "blogs",
    element: <Blogs api={NEWS_AND_EVENTS_API} />,
  },
  {
    path: "socialLinks",
    element: <SocialLinks api={SOCIAL_LINKS_API} />,
  },
  {
    path: "ecommercesSettings",
    element: <EcommercesSettings />,
  },
  {
    path: "vat",
    element: <VAT api={VAT_API} />,
  },
  {
    path: "promoCode",
    element: <PromoCode api={PROMO_CODE_API} />,
  },
  {
    path: "discounts",
    element: <Discount api={DISCOUNT_API} />,
  },
  {
    path: "delivery-fees",
    element: <DeliveryFee api={DISCOUNT_FEE_API} />,
  },
  {
    path: "colors",
    element: <Colors api={COLORS_API} />,
  },
  {
    path: "Users",
    element: <Users />,
  },
  {
    path: "employeeScreen",
    element: <EmployeeScreen />,
  },
  {
    path: "employees",
    element: <Employees api={ALL_EMPLOYEE_API} />,
  },
  {
    path: "employeeInvites",
    element: <InviteEmployees api={EMPLOYEES_API} />,
  },
  {
    path: "customers",
    element: <Customers api={CUSTOMERS_API} />,
  },
  {
    path: "wishlist_from_customer/:customerID",
    element: <WishlistFromCustomer api={CUSTOMERS_API} />,
  },
  {
    path: "orders_from_customer/:customerID",
    element: <OrdersFromCustomer api={CUSTOMERS_API} />,
  },
  {
    path: "addresses_from_customer/:customerID",
    element: <AddressesFromCustomer api={CUSTOMERS_API} />,
  },
  {
    path: "orders",
    element: <OrdersScreen />,
  },
  {
    path: "ordersFromStatus/:status",
    element: <OrdersFromStatus />,
  },

  {
    path: "orderItemsFromOrder/:orderID",
    element: <OrderItems />,
  },
  {
    path: "adminOrders",
    element: <AdminOrder />,
  },
  {
    path: "dealerLocations",
    element: <DealerDivisions />,
  },
  {
    path: "dealerAreas/:id",
    element: <DealerAreas />,
  },
  {
    path: "dealers/:id",
    element: <Dealers />,
  },

  {
    path: "salesReports",
    element: <SaleReports />,
  },

];

export default MainRoutes;
