import { useEffect, useState } from "react"
import useAxiosAuthInstance from "../../../../../../Utilities/Hooks/axiosAuthInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";

function ViewAddress({ api, targetID, toggleFetch }) {
  const axiosInstance = useAxiosInstance();
  const { info: address } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "singleAddress/" + targetID,
    toggleFetch,
  });
  return (
    <div className="crud_view_content">
      <h1>Address Name</h1>
      <p>{address?.name}</p>

      <h1>Address Type</h1>
      <p>{address?.type}</p>

      {/* <h1>Area</h1>
      <p>{areas?.find((area) => area._id === address?.area).name}</p> */}

      <h1>Full Address</h1>
      <p>{address?.fullAddress}</p>

      <h1>Zip Code</h1>
      <p>{address?.zipCode}</p>
    </div>
  );
}

export default ViewAddress;
