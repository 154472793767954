import { useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { CreateButton } from "../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { EditButton, ViewButton } from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";
import { useUseOrientation } from "../../../../../Utilities/Hooks/useUseOrientation";
import CreateFeaturedItem from "./CreateFeaturedItem";
import UpdateFeaturedItem from "./UpdateFeaturedItem";
import { ImageCell } from "../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import ViewFeaturedItem from "./ViewFeaturedItem";


function FeaturedItems({ api }) {

    const [showActionButton, setShowActionButton] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showViewSection, setShowViewSection] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);

    const [targetID, setTargetID] = useState(null);

    const { portrait } = useUseOrientation()
    const axiosInstance = useAxiosInstance();

    const { info: featuredItems } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + "/getAllFeaturedItems",
        toggleFetch
    });

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <div className="screen_wrapper">
            <div className="screen_header" >
                <h1 className="screen_heading">
                    Featured Items ({featuredItems?.length} in total)
                </h1>
                <CreateButton
                    screenTopicSingular={"Featured Items"}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable >
                <CRUDTableHeader>
                    <CRUDth th="Image" />
                    <CRUDth th="Items" />
                    <CRUDth th="precedence" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {featuredItems?.map((featuredItem) => (
                        <tr key={featuredItem._id}>
                             <ImageCell imgSrc={featuredItem?.item?.image} imgAlt={featuredItem?.item.name} />
                            <td>{featuredItem?.item?.name}</td>
                            <td>{featuredItem?.precedence}</td>
                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === featuredItem._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(featuredItem._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            {/* <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={featuredItem._id}
                                                setTargetID={setTargetID}
                                            /> */}
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={featuredItem._id}
                                                setTargetID={setTargetID}
                                            />
                                        </div>
                                    )}
                                    {portrait && showActionButton && targetID === featuredItem._id && (
                                        <div className="action_buttons">
                                            {/* <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={featuredItem._id}
                                                setTargetID={setTargetID}
                                            /> */}
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={featuredItem._id}
                                                setTargetID={setTargetID}
                                                setShowActionButton={setShowActionButton}
                                            />
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Featured Items`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateFeaturedItem
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}
                        api={api}
                    />

                </Modal>
            )}
            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Contact Info`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateFeaturedItem
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                        api={api}
                    />
                </Modal>
            )}

            {/* {showViewSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`View Contact Info`}
                    setShowModalContent={setShowViewSection}
                >
                    <ViewFeaturedItem
                        targetID={targetID}
                        api={api}
                    />
                </Modal>
            )} */}

        </div>
    );
}

export default FeaturedItems;
