import {  useEffect, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"
import { DEALER_API } from '../../../../../Utilities/APIs/DealerDivisionAPIs/DealerDivisionAPIs';
import EmailInput from "../../../../Partials/Forms/FormInputs/EmailInput/EmailInput"
import { useGetInfo } from "antopolis-react-utils/hooks"

function UpdateDealer({   setShowUpdateForm, targetID, triggerFetch, dealerAreaID}) {
    
    const [shopName, setShopName] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')

    const [isEmailValid, setIsEmailValid] = useState(false);

    const axiosInstance = useAxiosInstance()


    const { info: dealer } = useGetInfo({
        axiosInstance: axiosInstance,
        api: DEALER_API + 'singleDealer/' + targetID,
    })

    useEffect(() => {
        if (dealer) {
            setShopName(dealer.shopName)
            setName(dealer.name)
            setEmail(dealer.email)
            setPhone(dealer.phone)
            setAddress(dealer.address)
        }
    }, [dealer])


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('shopName', shopName)
        itemData.append('name', name)
        itemData.append('email', email)
        itemData.append('phone', phone)
        itemData.append('address', address)
        itemData.append('dealerArea', dealerAreaID)

        const response = await axiosInstance.patch(DEALER_API + 'singleDealer/' + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <ShortTextInput 
                    label={`Shop Name`}
                    value={shopName}
                    placeholder={`Enter Shop Name`}
                    setState={setShopName}  
                    
                />
                <ShortTextInput
                    label={`Name`}
                    value={name}
                    placeholder={`Enter Name`}
                    setState={setName}

                />
                <EmailInput
                    label={`Email`}
                    value={email}
                    placeholder={`Enter Email`}
                    setState={setEmail}
                    setIsValid={setIsEmailValid}


                />
                <ShortTextInput
                    label={`Phone`}
                    value={phone}
                    placeholder={`Enter Phone`}
                    setState={setPhone}

                />
                <ShortTextInput
                    label={`Address`}
                    value={address}
                    placeholder={`Enter Address`}
                    setState={setAddress}

                />

                <FormSubmitButton text="Update Dealer" />
            </Form>
        </div >
    )
}

export default UpdateDealer