import { useState, useContext } from "react";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../../Partials/Forms/Form";
import ImageInput from "../../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import NumberInput from "../../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import { ITEM_ASSETS_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";

function CreateItemImage({ itemId, setShowCreateForm }) {
  const [image, setImage] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [error, setError] = useState("");

  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const assetData = new FormData();
    assetData.append("item", itemId);
    assetData.append("image", image);
    assetData.append("precedence", precedence);

    try {
      const response = await axiosInstance.post(
        ITEM_ASSETS_API + 'createItemAsset',
        assetData
      );
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
      }
    } catch (error) {
      setError("An error occurred while creating the asset. Please try again.");
    }
  }

  return (
    <div className="create_asset_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowCreateImage
        >
          Upload Asset Image (550 x 650)
        </ImageInput>
        
        <NumberInput
          label="Precedence"
          value={precedence}
          setState={setPrecedence}
          placeholder="Enter Precedence"
        />

        <FormSubmitButton text="Create Asset" />
      </Form>
    </div>
  );
}

export default CreateItemImage;
