import { useContext, useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useUseOrientation } from "../../../../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import { ITEM_ASSETS_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { CreateButton } from "../../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import {
  ArchiveButton,
  EditButton,
  ViewButton,
} from "../../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { useParams } from "react-router-dom";
import { ImageCell } from "../../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { Modal } from "../../../../../../Partials/Elements/Modal/Modal";
import CreateItemColors from "./CreateItemColors";
import FilterSelect from "../../../../../../Partials/Forms/FilterSelect/FilterSelect";
import ArchiveItem from "../../../../../../Partials/Layout/ArchiveItem/ArchiveItem";

function ItemColors({ api }) {
  const [showModal, setShowModal] = useState(false);
  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showArchiveSection, setShowArchiveSection] = useState(false);
  const [filter, setFilter] = useState("active");
  const [isActive, setIsActive] = useState(null);

  const { itemId } = useParams();

  const [targetID, setTargetID] = useState(null);

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();
  const { toggleFetch, triggerFetch } = useContext(LayoutContext);

  const { info: itemColors } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "singleItemColorsByItem/" + itemId + `?filter=${filter}`,
    toggleFetch,
  });

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">
        Item Colors ({itemColors?.length} in total)
        </h1>
        <FilterSelect filter={filter} setFilter={setFilter} />
        <CreateButton
          screenTopicSingular={"Item Color"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Color" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {itemColors?.map((itemColors) => (
            <tr key={itemColors._id}>

              <td>
                <p>{itemColors?.color?.name}</p>
              </td>
             

              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      if (targetID === itemColors._id) {
                        setTargetID("");
                      } else {
                        setShowActionButton(true);
                        setTargetID(itemColors._id);
                      }
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">

                      <ArchiveButton
                        setShowArchiveForm={setShowArchiveSection}
                        targetID={itemColors._id}
                        setTargetID={setTargetID}
                        isActive={itemColors?.isActive}
                        setActive={setIsActive} />
                    </div>
                  )}
                  {portrait &&
                    showActionButton &&
                    targetID === itemColors._id && (
                      <div className="action_buttons">

                      
                        <ArchiveButton
                          setShowArchiveForm={setShowArchiveSection}
                          targetID={itemColors._id}
                          setTargetID={setTargetID}
                          isActive={itemColors?.isActive}
                          setActive={setIsActive} />
                      </div>
                    )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>


      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Item Benefit`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateItemColors
            itemId={itemId}
            setShowCreateForm={setShowCreateForm}
            api={api}
          />
        </Modal>
      )}

     


      {showArchiveSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Archive Item Image`}
          setShowModalContent={setShowArchiveSection}
        >
          <ArchiveItem
            api={`${api}archiveItemColor/`}
            isActive={isActive}
            targetID={targetID}
            setShowModal={setShowModal}
            setShowActionButton={setShowArchiveSection}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </div>
  );
}

export default ItemColors;
