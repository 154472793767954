import { useContext, useEffect, useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";

import { useUseOrientation } from "../../../../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../../../../Contexts/GlobalContexts/LayoutContext";
import { NAV_COLLECTION_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { CreateButton } from "../../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import {
  EditButton,
  ViewButton,
} from "../../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../../../Partials/Elements/Modal/Modal";

import UpdateNavCollection from "./UpdateNavCollection";
import CreateNavCollection from "./CreateNavCollection";
import ViewNavCollection from "./ViewNavCollection";

function NavCollection() {
  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);

  const [targetID, setTargetID] = useState(null);

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();
  const { toggleFetch } = useContext(LayoutContext);



  const { info: collections } = useGetInfo({
    axiosInstance: axiosInstance,
    api: NAV_COLLECTION_API + "getAllNavCollections",
    toggleFetch,
  });

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">
          Nav Collections ({collections?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Nav Collection"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Collection ID" />
          {/* <CRUDth th="Precedence" /> */}
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {collections &&
            collections.length > 0 &&
            collections?.map((collection) => (
              <tr key={collection._id}>
                <td>{collection?.collectionID?.name}</td>
                {/* <td>{collection.precedence}</td> */}
                <td>
                  <div className="action_buttons_wrapper">
                    <button
                      className="three_dots"
                      onClick={() => {
                        if (targetID === collection._id) {
                          setTargetID("");
                        } else {
                          setShowActionButton(true);
                          setTargetID(collection._id);
                        }
                      }}
                    >
                      <IconThreeDots />
                    </button>
                    {!portrait && (
                      <div className="action_buttons">
                        {/* <ViewButton
                          setShowViewSection={setShowViewSection}
                          targetID={collection._id}
                          setTargetID={setTargetID}
                        /> */}
                        <EditButton
                          setShowUpdateForm={setShowUpdateForm}
                          targetID={collection._id}
                          setTargetID={setTargetID}
                        />
                      </div>
                    )}
                    {portrait &&
                      showActionButton &&
                      targetID === collection._id && (
                        <div className="action_buttons">
                          <ViewButton
                            setShowViewSection={setShowViewSection}
                            targetID={collection._id}
                            setTargetID={setTargetID}
                          />
                          <EditButton
                            setShowUpdateForm={setShowUpdateForm}
                            targetID={collection._id}
                            setTargetID={setTargetID}
                            setShowActionButton={setShowActionButton}
                          />
                        </div>
                      )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>

        {/* <Pagination
          totalPages={totalPages}
          totalItems={totalClients}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        /> */}
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Collection`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateNavCollection setShowCreateForm={setShowCreateForm} />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Collection`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateNavCollection
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
          />
        </Modal>
      )}
      {/* 

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Client`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateClient
            employee={employee}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Client`}
          setShowModalContent={setShowViewSection}
        >
          <ViewClient triggerFetch={triggerFetch} targetID={targetID} />
        </Modal>
      )} */}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Nav Collection`}
          setShowModalContent={setShowViewSection}
        >
          <ViewNavCollection targetID={targetID} />
        </Modal>
      )}
    </div>
  );
}

export default NavCollection;
