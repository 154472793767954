import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { ITEM_ASSETS_API } from "../../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { Image } from "../../../../../../Partials/Elements/Image/Image";
import { useEffect, useState } from "react";
import { ImageCell } from "../../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";

function ViewItemLargeImage({ assetId,api }) {
  const axiosInstance = useAxiosInstance();
  const [error, setError] = useState("");

  const {
    info: asset,
    loading,
    error: fetchError,
  } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "singleItemLargeImage/" + assetId,
  });

  useEffect(() => {
    if (fetchError) {
      setError("Failed to fetch asset data. Please try again.");
    }
  }, [fetchError]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error_message">{error}</div>;
  }

  return (
    <div className="crud_view_content">
      {asset ? (
        <>
         

          <h1>Image</h1>
          <ImageCell
            imgSrc={asset.image}
            imgAlt={asset.name}
            className="item-image"
          />
        </>
      ) : (
        <div>No asset details available.</div>
      )}
    </div>
  );
}

export default ViewItemLargeImage;
